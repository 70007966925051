import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import JSONPretty from "react-json-pretty";
import {
    BrowserRouter as Router,
    Link,
    useParams
} from "react-router-dom"
import Navi from '../include/Navi'
import Foot from '../include/Foot'
import Head from '../include/Head'
import { withRouter } from 'react-router'



function OptionEdit({ evtId }) {
    const [image, setImage] = useState(null)
    const [posts, setPosts] = useState([])
    const { idlisttwoedit } = useParams()
    const [message, setMessage] = useState('')
    const [messaget, setMessaget] = useState('')
    const [messageth, setMessageth] = useState('')
    const { idpic } = useParams();
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [updatesData, setUpdatesData] = useState({
        refoption: "",
        designationoption: "",
        crpoption: "",
        priceoption: "",
        stateoption: "true",
    })

    function updateEdit(e) {

        const newupdate = { ...updatesData }
        newupdate[e.target.id] = e.target.value
        setUpdatesData(newupdate)
        console.log(newupdate)
    }

    const handleChange = (event) => {
        setMessage(event.target.value)
        updateEdit(event)
    }

    const handleChanget = (event) => {
        setMessaget(event.target.value)
        updateEdit(event)
    }

    const handleChangeth = (event) => {
        setMessageth(event.target.value)
        updateEdit(event)
    }


    useEffect(() => {
        axios.get(url.api_url + '/api/option-managements/' + idlisttwoedit)
            .then(response => {
                setUpdatesData({
                    ...updatesData,
                    refoption: response.data.data.attributes.refoption,
                    designationoption: response.data.data.attributes.designationoption,
                    crpoption: response.data.data.attributes.crpoption,
                    priceoption: response.data.data.attributes.priceoption,
                });
            })
            .catch(err => console.error(err));
    }, []);

    useEffect(() => {
        axios.get(url.api_url + '/api/option-managements?populate=*')
            .then(res => {
                setPosts(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])


    const handleSubmit = async (e) => {
        console.log('handleSubmit')
        e.preventDefault()

        const formData = new FormData()
        formData.append('files', image) // the pic
        formData.append('ref', 'api::option-management.option-management') // link with my table
        formData.append('refId', evtId)
        //formData.append('field', 'picoption') // the row
        if (message.trim().length !== 0 && messaget.trim().length !== 0 &&
            messageth.trim().length !== 0) {
            axios.post(url.api_url + '/api/upload/', formData).then(res => {
                console.log(res.data[0].id);
                const res2 = axios.put(url.api_url + `/api/option-managements/` + idlisttwoedit, {
                    "data": {
                        refoption: updatesData.refoption,
                        designationoption: updatesData.designationoption,
                        crpoption: updatesData.crpoption,
                        priceoption: updatesData.priceoption,
                        picoption: res.data[0].id,
                    }
                }
                )
                alert("Option modifié")
                if (res2.ok) {
                    console.log(res2)
                    console.log('res.ok')
                    console.log('res', res2)
                }
            }).catch(error => {
                console.log(error.message);
            })
        } else {
            console.log("problème")
        }
    }

    const handleFileChange = (e) => {
        if (e.target.files[0].name.split('.').pop() !== "png" && e.target.files[0].name.split('.').pop() !== "jpg" &&
            e.target.files[0].name.split('.').pop() !== "jpeg") {
            alert("Il y a un problème avec le format de l'image")
        } else {
            setImage(e.target.files[0])
        }
    }

    function deletePic(idpic) {
        if (image.name === 0 || message.trim().length === 0 || messaget.trim().length === 0 ||
            messageth.trim().length === 0) {
            alert("Il y a un problème avec l'image ou un champ n'a pas été rempli")
        } else {
            axios.delete(url.api_url + `/api/upload/files/` + idpic)
        }
    }

    return (
        <div class="page-container">
            <Navi />
            <div class="main-content">
                <Head />
                <div class="main-content-inner">
                    <div class="col-lg-6 mt-5">
                        <div class="card">
                            <div class="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div class="Image">
                                        <div class="icon-container">
                                            <span class="ti-plus"></span>
                                        </div>
                                        <input className='file-plus' type='file' onChange={handleFileChange} />
                                        {image ? (
                                                <div class="icon-container">
                                                    <img class="Image__Icon" src={URL.createObjectURL(image)} alt="Thumb" />

                                                </div>
                                            ) : (
                                                posts?.map((post) => (
                                                    <div class="icon-container">
                                                        {post.id == idlisttwoedit ? <img class="Image__Icon" src={url.api_url + post.attributes.picoption.data.attributes.url} alt="Thumb" /> : null}
                                                    </div>
                                                )))}
                                    </div>
                                    <div class="single-table">

                                        <div className="product__list__two">

                                            <div className='tableform__color option__table__two'>
                                                <p>RÉF.</p>
                                                <p>DÉSIGNATION</p>
                                                <p>CRP.</p>
                                                <p>PRIX</p>
                                            </div>
                                            <div className='option__table__two'>
                                                <input className="option__table__two__input" onChange={(e) => handleChange(e)} id="refoption" value={updatesData.refoption} type="text" />
                                                <textarea className='option__table__two__textarea' onChange={(e) => handleChanget(e)} id="designationoption" value={updatesData.designationoption} type="text" placeholder={posts.designationoption} />
                                                <input className="option__table__two__input" onChange={(e) => handleChangeth(e)} id="crpoption" value={updatesData.crpoption} type="text" placeholder={posts.crpoption} />
                                                <input className="option__table__two__input" onChange={(e) => handleChangeth(e)} id="priceoption" value={updatesData.priceoption} type="text" placeholder={posts.priceoption} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-5">
                                        <div className="card TableForm__BottomButton">
                                            <div className="card-body">
                                                <input type='submit' value='Enregister' className='btn btn-primary mb-3' onClick={(e) => deletePic(idpic, e)} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Foot />
        </div>
    )
}

export default OptionEdit