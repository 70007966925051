import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import './FrontIndex.css'
import FrontCient from './FrontClient'
import FrontOption from './FrontOption'
import FrontProduct from './FrontProduct'
import FrontFinalList from './FrontFinalList'
import * as url from '../include/var.js'
import { BrowserRouter as Router,useParams } from 'react-router-dom';


function FrontIndex({ evtId }) {
    const [clickClient, setClickClient] = useState(true); // Afficher le contenu de base
    const [clickProduct, setClickProduct] = useState(false);
    const [clickOption, setClickOption] = useState(false);
    const [posts, setPosts] = useState()
    const [finalLists, setFinalLists] = useState()
    const [finalId, setFinalId] = useState()
    const [finalListId, setFinalListId] = useState()
    const [selectedId, setSelectedId] = useState(null);
    const [foldedStates, setFoldedStates] = useState({});
    const [selectedPost, setSelectedPost] = useState(null);
    //Pour récuperer ce qu'il y a dans le FrontProduct
    const [selectedItems, setSelectedItems] = useState({
        customer: [],
        finallists: [],
        option_managements: [],
        testuns: [],
        finallistuns: [],
        beorderfinallists: [],
        beorderoptions: [],
        beordertestuns: [],
        users_permissions_user: []
    });


    const handleSelectedIdChange = (newSelectedId) => {
        setSelectedId(newSelectedId);
    };

    useEffect(() => {
        axios.get(url.api_url + '/api/tests?populate=*&sort=id:desc')
            .then(res => {
                setPosts(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })

    }, [])

    useEffect(() => {
        axios.get(url.api_url + '/api/finallists?populate=*&sort=id:desc')
            .then(res => {
                setFinalLists(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (selectedId !== null && posts) {
            const filteredPosts = posts.filter(post => post.id === selectedId);
            filteredPosts.forEach(post => {
                setFinalId(post.attributes.idfinal);
            });
        }
    }, [selectedId, posts]);

    const handleSubmit = (values) => {
        const formData = new FormData()
        formData.append('ref', 'api::beorder.beorder')
        formData.append('refId', evtId)
        const uniqueFinallists = [...new Set(selectedItems.finallists)];
        const uniqueCustomers = [...new Set(selectedItems.customer)];
        const uniqueOptionManagements = [...new Set(selectedItems.option_managements)];
        const uniqueTestuns = [...new Set(selectedItems.testuns)];
        const uniqueFinallistuns = [...new Set(selectedItems.finallistuns)]
        axios.post(url.api_url + '/api/beorders/', {
            "data": {
                "finallists": uniqueFinallists,
                "customer": uniqueCustomers,
                "option_managements": uniqueOptionManagements,
                "testuns": uniqueTestuns,
                "finallistuns": uniqueFinallistuns,
                "users_permissions_user": localStorage.id,

            }

        }, {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer VotreTokenJWT' // Si l'authentification est nécessaire
            }
        }).then(response => {
            const beorderId = response.data.data.id;

            createBeorderOptions(beorderId, uniqueOptionManagements)
            createBeorderTestUn(beorderId, uniqueTestuns)
            createBeorderFinallists(beorderId, uniqueFinallists)

        }).catch(error => {
            console.error("Erreur lors de la création du beorder", error);
        })
    };

    function createBeorderTestUn(beorderId, uniqueTestuns) {
        uniqueTestuns.forEach(item => {
            console.log(item)
            axios.post(`${url.api_url}/api/beordertests`, {
                "data": {
                    "idtestun": item.toString(), // Assurez-vous que cela correspond à votre modèle de données
                    "beorder": beorderId
                }
            }).catch(error => console.error("Erreur lors de la création de BeorderTestun", error));
        });
    }
    function createBeorderOptions(beorderId, uniqueOptionManagements) {
        uniqueOptionManagements.forEach(item => {
            axios.post(`${url.api_url}/api/beorder-options`, {
                "data": {
                    "idoption": item.toString(), // Assurez-vous que cela correspond à votre modèle de données
                    "beorder": beorderId
                }
            }).catch(error => console.error("Erreur lors de la création de beorder-options", error));
        });
    }

    function createBeorderFinallists(beorderId, uniqueFinallists) {
        uniqueFinallists.forEach(item => {
            axios.post(`${url.api_url}/api/beorderfinallists`, {
                "data": {
                    "idfinallist": item.toString(), // Assurez-vous que cela correspond à votre modèle de données
                    "beorder": beorderId
                }
            }).catch(error => console.error("Erreur lors de la création de beorderfinallists", error));
        });
    }

    const toggleFold = (id) => {
        setFoldedStates(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    return (
        <div className='front__background'>
            <Formik
                initialValues={{
                }}
            // onSubmit={handleSubmit}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <div className='front__left'>
                            <div className='front__left__logo'>
                                <img src={url.icon_logo} alt='logo' />
                            </div>


                            <div className='front__left__info'>
                                <ul>
                                    <li className='front__list__open'>
                                        <p>Liste des clients</p>
                                        <ul className={clickClient ? 'front__list__nested' : 'front__list__nested hidden'}>
                                            <FrontCient foldedStates={foldedStates}
                                                toggleFold={toggleFold}
                                                frontFinalListid={finalId}
                                                setFieldValue={setFieldValue}
                                                selectedItems={selectedItems}
                                                setSelectedItems={setSelectedItems} />
                                        </ul>
                                    </li>
                                    <li className='front__list__open'>
                                        <p>Produits</p>
                                        <FrontProduct onSelectedIdChange={handleSelectedIdChange}
                                            selectedItems={selectedItems}
                                            setSelectedItems={setSelectedItems}
                                        />
                                    </li>
                                    <li className='front__list__open'>
                                        <p>Options</p>
                                        <FrontOption foldedStates={foldedStates}
                                            toggleFold={toggleFold}
                                            frontFinalListid={finalId}
                                            setFieldValue={setFieldValue}
                                            selectedItems={selectedItems}
                                            setSelectedItems={setSelectedItems} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='front__list__right'>
                            <div className={finalId ? 'front__border__end' : 'front__list__nested hidden'}>
                                {finalId ? <FrontFinalList foldedStates={foldedStates}
                                    toggleFold={toggleFold}
                                    frontFinalListid={finalId}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems} />
                                    :
                                    null}
                            </div>
                        </div>
                        <Link to={url.estimate_api_front} ><p className='btn btn-secondary'>Devis</p></Link>
                        <button className='btn btn-secondary' style={{margin: '10px'}} type="submit" onClick={handleSubmit}>Envoyer</button>

                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default FrontIndex;