import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import Navi from '../include/Navi'

import FinalList from './FinalList'
import Foot from '../include/Foot'
import {
    Link,
    useParams
} from "react-router-dom"
import Head from '../include/Head'

function FinalGridList() {
    const [posts, setPosts] = useState()
    const [options, setOptions] = useState()
    const { idlist } = useParams()

    useEffect(() => {
        axios.get(url.api_url + '/api/finals?populate=*')
            .then(res3 => {
                setPosts(res3.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        axios.get(url.api_url + '/api/option-managements?populate=*')
            .then(res3 => {
                setOptions(res3.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <div>
            <div className="scren__size">
                <Head />
                <Navi />
                <div className="product__list">
                    <div className='col-lg-12 col-md-6 mt-5 '>
                        {posts?.map((post) => (
                            <div className={post.id == idlist ? 'card ' : 'template'} key={post.id}>

                                {post.id == idlist ? console.log('test') : null}
                                {post.id == idlist ? <Link to={url.final_create_list + '/' + post.id} ><p className='btn btn-primary'>Créer</p></Link> : null}
                            </div>
                        ))}
                        <div className='card__present'>
                            {posts?.map((post) => (
                                <div className={post.id == idlist ? 'card ' : 'template'}  key={post.id}>

                                    <div className='card__present'>
                                        {post.id == idlist ? <img src={url.api_url + post.attributes.pictest.data.attributes.url} alt='produit final' /> : null}
                                    </div>
                                </div>
                            ))}
                            {posts?.map((post) => (
                                <div className={post.id == idlist ? 'card ' : 'template'} key={post.id}>
                                    <h4>DESCRIPTIF</h4>
                                    {post.id == idlist ? <p className='product__descrpt__marge'>{post.attributes.descriptiontest}</p> : null}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='tableform__color final__list__table'>
                        <p>DÉSIGNATION</p>
                        <p>CODE</p>
                        <p>CRP</p>
                        <p>PRIX</p>
                        <p>OPTIONS</p>
                    </div>
                    <FinalList />
                </div>
                <Foot />
            </div>
        </div>
    )
}

export default FinalGridList