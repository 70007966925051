import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import JSONPretty from "react-json-pretty";
import Select from 'react-select/async'
import {
    BrowserRouter as Router,
    Link,
    useParams
} from "react-router-dom"
import Navi from '../include/Navi'
import Foot from '../include/Foot'
import Head from '../include/Head'
import { withRouter } from 'react-router'



function ProductGridApifinalbisun({ evtId }) {
    const [posts, setPosts] = useState([])
    const { idlist } = useParams()
    const [message, setMessage] = useState('')

    const [messagef, setMessagef] = useState('')
    const [image, setImage] = useState(null)
    const [firstData, setFirstData] = useState({
        titletestun: "",
        idfinalun: "",
        final: true,
    })
    const [updatesData, setUpdatesData] = useState({
        titletestun: "",
        descriptiontestun: "",

    })



    function updateEdit(e) {

        const newupdate = { ...updatesData }
        const newupdates = { ...firstData }
        newupdate[e.target.id] = e.target.value
        newupdates[e.target.id] = e.target.value
        setUpdatesData(newupdate)
        setFirstData(newupdates)
        // console.log(newupdate)
    }

    const handleChange = (event) => {
        setMessage(event.target.value)
        updateEdit(event)
    }

    const handleChangef = (event) => {
        setMessagef(event.target.value)
        updateEdit(event)
    }


    const handleSubmit = async (e) => {
        console.log('handleSubmit')
        e.preventDefault()

        const formData = new FormData()
        formData.append('files', image) // the pic
        formData.append('ref', 'api::product-product.product-product') // link with my table
        formData.append('refId', evtId)
        //formData.append('field', 'picproductgroup') // the row
        if (message.trim().length !== 0 &&
            messagef.trim().length !== 0) {
            axios.post(url.api_url + '/api/upload/', formData).then(res => {
                // console.log(res.data[0].id);
                const res2 = axios.post(url.api_url + `/api/finaluns/`, {
                    "data": {
                        titletestun: updatesData.titletestun,
                        descriptiontestun: updatesData.descriptiontestun,
                        pictestun: res.data[0].id,
                    }
                }
                ).then(res2 => {
                    console.log("l'id de finaluns est " + res2.data.data.id)
                    axios.post(url.api_url + `/api/testuns/`, {
                        "data": {
                            titletestunun: updatesData.titletestunun,
                            final: true,
                            idfinalun: res2.data.data.id,
                            pictest: res.data[0].id,
                        }
                    }
                    )
                })

                if (res2.ok) {
                    console.log(res2)
                    console.log('res.ok')
                    console.log('res', res2)
                    // imageUploaded()
                }
            }).catch(error => {
                console.log(error.message);
            })
            console.log("La fiche a été crée")
        } else {
            alert("Un des champs n'a pas été rempli")
        }


        //e.preventDefault()
    }


    const loadOptions = () => {
        return fetch(url.api_url + '/api/option-managements')
            .then((res) => res.json())
            .then((data) => data.data);
    }

    const handleFileChange = (e) => {

        if (e.target.files[0].name.split('.').pop() !== "png" && e.target.files[0].name.split('.').pop() !== "jpg" &&
            e.target.files[0].name.split('.').pop() !== "jpeg") {
            alert("Il y a un problème avec le format de l'image")
        } else {
            setImage(e.target.files[0])
        }
    }

    return (
        <div class="page-container">
            <Navi />
            <div class="main-content">
                <Head />
                <div class="main-content-inner">
                    <div class="col-lg-6 mt-5">
                        <div class="card">
                            <div class="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className='select__space'>
                                        <div class="Image">
                                            <div class="icon-container">
                                                <span class="ti-plus"></span>
                                            </div>
                                            <input className='file-plus' type='file' onChange={handleFileChange} />
                                            {image && (
                                                <div class="icon-container">
                                                    <img class="Image__Icon" src={URL.createObjectURL(image)} alt="Thumb" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <h4 className="header-title">Titre</h4>
                                    <input className="option__table__two__input" onChange={(e) => handleChange(e)} id="titletestun" value={updatesData.titletestun} type="text" placeholder={posts && posts.titletestun ? posts.titletestun : null} />
                                    <h4 className="header-title">DESCRIPTIF GENERAL</h4>
                                    <textarea className='option__table__two__textarea TableForm' onChange={(e) => handleChangef(e)} id="descriptiontestun" value={updatesData.descriptiontestun} type="text" placeholder={posts && posts.descriptiontestun ? posts.descriptiontestun : null} />
                                    <div className="col-lg-6 mt-5">
                                        <div className="card TableForm__BottomButton">
                                            <div className="card-body">
                                                <input type='submit' value='Enregister' className='btn btn-primary mb-3' />
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Foot />
        </div>
    )
}

export default ProductGridApifinalbisun