import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import './FrontOption.css'
import { useField } from 'formik';
import { Link } from "react-router-dom"

function FrontOption({ foldedStates, toggleFold, frontFinalListid, setFieldValue, selectedItems, setSelectedItems }) {
    const [posts, setPosts] = useState()
    const [selectedPosts, setSelectedPosts] = useState([]); // Utiliser un tableau pour stocker les ID des posts sélectionnés
    const [field, meta] = useField('finalName');

    useEffect(() => {
        axios.get(url.api_url + '/api/option-managements?populate=*&sort=id:desc')
            .then(res => {
                setPosts(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const handleCheckboxClick = (e, id, isProduct) => {
        e.stopPropagation();

        setSelectedItems(prevState => {
            // Clone de l'état précédent
            const newSelection = { ...prevState };

            // Sélectionner ou désélectionner l'élément
            if (isProduct == "options") {
                if (e.target.checked) {
                    newSelection.option_managements.push(id);
                } else {
                    newSelection.option_managements = newSelection.option_managements.filter(item => item !== id);
                }
            }

            return newSelection;
        })
    }

    return (
        <div className='front__list__nested__img'>
            {posts?.map((post) => (
                <div key={post.id}>
                    <input
                        {...field}
                        type="checkbox"
                        name='option_managements'
                        value={post.id}
                        id={post.id}
                        onClick={(e) => handleCheckboxClick(e, post.id, "options")}
                        checked={selectedItems.option_managements?.includes(post.id)} />
                    <img src={post.attributes.picoption && post.attributes.picoption.data.attributes.url ? url.api_url + post.attributes.picoption.data.attributes.url : null}
                        alt="options"
                    />
                </div>
            ))}
        </div>
    )
}

export default FrontOption