import React, { useState, useEffect } from 'react'
import axios from 'axios'
import JSONPretty from "react-json-pretty"
import Select from 'react-select/async'
import * as url from '../include/var.js'
import {
  BrowserRouter as Router,
  Link,
  useParams
} from "react-router-dom"
import Navi from '../include/Navi'
import Foot from '../include/Foot'
import Head from '../include/Head'
import { withRouter } from 'react-router'



function ProductGridApiFinalCreatebis({ evtId }) {
  const [image, setImage] = useState(null)
  const [isClick, setIsClick] = useState(false)
  const [posts, setPosts] = useState([])
  const [selects, setSelects] = useState([])
  const [results, setResults] = useState([])
  const { idlist } = useParams()
  const [message, setMessage] = useState('')
  const [messaget, setMessaget] = useState('')
  const [messageth, setMessageth] = useState('')
  const [messagef, setMessagef] = useState('')
  const [firstData, setFirstData] = useState({
    titletest: "",
    final: true,
  })
  const [updatesData, setUpdatesData] = useState({
    titletest: "",
    descriptiontest: "",
    id: idlist,
  })

  // function updateEdits(e) {
  //   const newupdate = { ...updatesData }
  //   newupdate["optionid"] = results
  //   console.log(e)
  //   setUpdatesData(newupdate)
  // }

  function updateEdit(e) {
    const newupdate = { ...updatesData }
    const newupdates = { ...firstData }
    newupdate[e.target.id] = e.target.value
    newupdates[e.target.id] = e.target.value
    setUpdatesData(newupdate)
    setFirstData(newupdates)
  }


  const handleChange = (event) => {
    setMessage(event.target.value)
    updateEdit(event)
  }

  const handleChangef = (event) => {
    setMessagef(event.target.value)
    updateEdit(event)
  }

  const handleSubmit = async (e) => {
    console.log('handleSubmit')
    e.preventDefault()

    const formData = new FormData()
    formData.append('files', image) // the pic
    formData.append('ref', 'api::product-product.product-product') // link with my table
    formData.append('refId', evtId)
    //formData.append('field', 'picproductgroup') // the row
    if (message.trim().length !== 0 && messagef.trim().length !== 0) {
      axios.post(url.api_url + '/api/upload/', formData).then(res => {
        // console.log(res.data[0].id);

        const res2 = axios.post(url.api_url + `/api/finals/`, {
          "data": {
            titletest: updatesData.titletest,
            descriptiontest: updatesData.descriptiontest,
            pictest: res.data[0].id,
            "option_managements":
              results
          }
        }
        ).then(res2 => {
          console.log("l'id de finaluns est " + res2.data)
          axios.post(url.api_url + `/api/tests/`, {
            "data": {
              titletest: updatesData.titletest,
              final: true,
              idtest: idlist,
              idfinal: res2.data.data.id,
              pictest: res.data[0].id,
            }
          }
          )
        })
        if (res2.ok) {
          console.log(res2)
          console.log('res.ok')
          console.log('res', res2)
        }
      }).catch(error => {
        console.log(error.message);
      })
      console.log("La fiche a été crée")
    } else {
      alert("Un des champs n'a pas été rempli")
    }
  }

  const handleFileChange = (e) => {
    if (e.target.files[0].name.split('.').pop() !== "png" && e.target.files[0].name.split('.').pop() !== "jpg" &&
      e.target.files[0].name.split('.').pop() !== "jpeg") {
      alert("Il y a un problème avec le format de l'image")
    } else {
      setImage(e.target.files[0])
    }
  }

  return (
    <div class="page-container">
      <Navi />
      <div class="main-content">
        <Head />
        <div class="main-content-inner">
          <div class="col-lg-6 mt-5">
            <div class="card">
              <div class="card-body">
                <form onSubmit={handleSubmit}>
                  <div className='select__space'>
                    <div class="Image">
                      <div class="icon-container">
                        <span class="ti-plus"></span>
                      </div>
                      <input className='file-plus' type='file' onChange={handleFileChange} />
                      {image && (
                        <div class="icon-container">
                          <img class="Image__Icon" src={URL.createObjectURL(image)} alt="Thumb" />
                        </div>
                      )}
                    </div>
                  </div>
                  <h4 className="header-title">Titre</h4>
                  <input className="option__table__two__input" onChange={(e) => handleChange(e)} id="titletest" value={updatesData.titletest} type="text" placeholder={posts && posts.titletest ? posts.titletest : null} />
                  <h4 className="header-title">DESCRIPTIF GENERAL</h4>
                  <textarea className='option__table__two__textarea TableForm' onChange={(e) => handleChangef(e)} id="descriptiontest" value={updatesData.descriptiontest} type="text" placeholder={posts && posts.descriptiontest ? posts.descriptiontest : null} />
                  <div className="col-lg-6 mt-5">
                    <div className="card TableForm__BottomButton">
                      <div className="card-body">
                      <input type='submit' value='Enregister' className='btn btn-primary mb-3' />
                        
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Foot />
    </div>
  )
}


export default ProductGridApiFinalCreatebis
