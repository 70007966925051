import React, { useState, useEffect, useReducer } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import { useField } from 'formik';
import { Link } from "react-router-dom"

function FrontClient({ foldedStates, toggleFold, frontFinalListid, setFieldValue, selectedItems, setSelectedItems }) {
    const [posts, setPosts] = useState()
    const [selectedPost, setSelectedPost] = useState(null);
    const [field, meta] = useField('finalName');
    // const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);



    useEffect(() => {
        axios.get(url.api_url + '/api/customers?populate=*&sort=id:desc')
            .then(res => {
                setPosts(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const handleClick = (post) => {
        setSelectedPost(post);
        toggleFold(post.id);
        setFieldValue('customer', post.id);
    }

    const handleCheckboxClick = (e, id, isProduct) => {
        e.stopPropagation();
    
        setSelectedItems(prevState => {
            // Crée un nouvel objet pour l'état
            const newSelection = { ...prevState };
    
            // Assigne l'ID sélectionné à la propriété appropriée en fonction de isProduct
            if (isProduct === "selectedCompanyId") {
                newSelection.customer = [id]; // Remplace par le nouvel ID sélectionné
            }
            return newSelection;
        });
    };

    return (
        <div>
            {posts?.map((post) => (
                <div key={post.id} onClick={() => handleClick(post)}
                    style={{ backgroundColor: post.id === selectedPost?.id ? 'lightgray' : 'white' }}>
                    <p>{post.attributes.company}</p>
                    <input className="hiddenCheckbox"  type="radio" name="customer" value={post.id} onClick={(e) => handleCheckboxClick(e, post.id, "selectedCompanyId")} />
                </div>
            ))}
        </div>
    )
}

export default FrontClient