import React, { Component } from 'react'
import Navi from '../include/Navi'
import OptionGrid from './OptionGrid'
import Foot from '../include/Foot'
import Head from '../include/Head'
import * as url from '../include/var.js'
import {
    Link,
} from "react-router-dom"



class OptionManagement extends Component {
    render() {
        return (
            <div>
                <div className="scren__size">
                    <Head />
                    <Navi />
                    <div className='col-lg-12 col-md-6 mt-5'>
                        <Link to={url.option_list} ><p className="btn btn-primary">Créer</p></Link>
                    </div>
                    <div className="product__list">
                        <div>
                            <div className='tableform__color option__table'>
                                <p>Image</p>
                                <p>Réf.</p>
                                <p>Désignation</p>
                                {/* <p>Déscription</p> */}
                                <p>Crp.</p>
                                <p>Prix</p>
                            </div>
                            <OptionGrid />
                        </div>
                    </div>
                </div>
                <Foot />
            </div>
        )
    }
}

export default OptionManagement
