import React, { useState } from 'react'
import * as url from './var.js'
import {
  Link,
} from "react-router-dom"


function Navi() {
  const [clickProduct, setClickProduct] = useState(false)
  const [clickOption, setClickOption] = useState(false)
  const [clickSetting, setClickSetting] = useState(false)
  const [clickOrder, setClickOrder] = useState(false)

  const handleClickProduct = () => {
    if (clickProduct === false) {
      setClickProduct(true)
      setClickOption(false)
      setClickOrder(false)
      setClickSetting(false)
    } else
      setClickProduct(false)
  }


  const handleClickOption = () => {
    if (clickOption === false) {
      setClickOption(true)
      setClickOrder(false)
      setClickProduct(false)
      setClickSetting(false)
    } else
      setClickOption(false)
  }

  const handleClickSetting = () => {
    if (clickSetting === false) {
      setClickSetting(true)
      setClickOption(false)
      setClickProduct(false)
      setClickOrder(false)
    } else
      setClickSetting(false)
  }

  const handleClickOrder = () => {
    if (clickOrder === false) {
      setClickOrder(true)
      setClickOption(false)
      setClickProduct(false)
      setClickSetting(false)
    } else
      setClickOrder(false)
  }

  return (
    <div className="sidebar-menu">
      <div className="sidebar-header">
        <div className="logo">
          <a href="#"><img src={url.icon_logo} alt="logo" /></a>
        </div>
      </div>
      <div className="main-menu">
        <div className="menu-inner">
          <nav>
            <ul className="metismenu" id="menu">
              <li onClick={handleClickProduct} className={clickProduct ? 'active' : ''}>
                <a href="javascript:void(0)" aria-expanded="true"><i className="ti-dashboard"></i><span>PRODUITS</span></a>
                <ul className={clickProduct ? 'collapse in' : 'collapse'}>
                  <Link to={url.products}>Liste des produits</Link>
                </ul>
              </li>
              <li onClick={handleClickOption} className={clickOption ? 'active' : ''}>
                <a href="javascript:void(0)" aria-expanded="true"><i className="ti-layout-sidebar-left"></i><span>OPTIONS
                </span></a>
                <ul className={clickOption ? 'collapse in' : 'collapse'}>
                  <Link to={url.option_management}>Gestion des options</Link>
                </ul>
              </li>
              <li onClick={handleClickSetting} className={clickSetting ? 'active' : ''}>
                <a href="javascript:void(0)" aria-expanded="true"><i className="ti-pie-chart"></i><span>RÉGLAGES</span></a>
                <ul className={clickSetting ? 'collapse in' : 'collapse'}>
                <li><Link to={url.customer_contact}>Comptes client</Link></li>
                <li><Link to={url.user_contact}>Comptes utilisateur</Link></li>
                </ul>
              </li>
              <li onClick={handleClickOrder} className={clickOrder ? 'active' : ''}>
                <a href="javascript:void(0)" aria-expanded="true"><i className="ti-palette"></i><span>COMMANDES</span></a>
                <ul className={clickOrder ? 'collapse in' : 'collapse'}>
                  <li><a href="invoice.html">Devis</a></li>
                  <li><Link to={url.order_tracking}>Suivi des devis</Link></li>
                  <li><a href="client_order.html">Commandes Clients</a></li>
                  <li><a href="client_tracking.html">Suivi des commandes Clients</a></li>
                  <li><a href="provider_order.html">Commandes Fournisseurs</a></li>
                  {/* <li><a href="provider_tracking.html">Suivi des Commandes Fournisseurs</a></li> */}
                </ul>
              </li>
              <li>
                <a href="javascript:void(0)" aria-expanded="true"><i className="fa fa-table"></i>
                  <span>CONDITION GENERAL VENTE</span></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Navi
