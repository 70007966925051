import { useEffect, useState } from "react";
import axios from "axios";
import * as url from "../include/var.js";
import { useParams, Link } from "react-router-dom";
import { updateQuantity } from "./updateDataQuantity";
import EstimateBeorder from "./EstimateBeorder.js";

function EstimateTemplate() {
  const [beorder, setBeorder] = useState([]);
  const [quantitys, setQuantitys] = useState();
  const [price, setPrice] = useState([]);
  const [discount, setDiscount] = useState({});
  const { idlist } = useParams();

  useEffect(() => {
    axios
      .get(`${url.api_url}/api/beorders/${idlist}?populate=*`)
      .then((res) => {
        setBeorder(res.data.data?.attributes);
      })
      .catch((err) => console.err(err));
  }, []);

  const InputId = {
    firstDiscountPourcent: "firstDiscountPourcent",
    secondDiscountPourcent: "secondDiscountPourcent",
    firstDiscountPrice: "firstDiscountPrice",
    secondDiscountPrice: "secondDiscountPrice",
  };

  const totalPrice = price.reduce((acc, curr) => acc + curr, 0);

  const countDiscount = (discountPourcentOrPrice, input) => {
    console.log(discountPourcentOrPrice);
    if (input === InputId.firstDiscountPourcent) {
      setDiscount({
        firstDiscountPourcent: Number(discountPourcentOrPrice),
        firstDiscountPrice:
          totalPrice * (1 - Number(discountPourcentOrPrice) / 100),
      });
    } else if (input === InputId.firstDiscountPrice) {
      setDiscount({
        firstDiscountPourcent:
          ((totalPrice - Number(discountPourcentOrPrice)) / totalPrice) * 100,
        firstDiscountPrice: Number(discountPourcentOrPrice),
      });
    } else if (input === InputId.secondDiscountPourcent) {
      setDiscount({
        secondDiscountPourcent: Number(discountPourcentOrPrice),
        secondDiscountPrice:
          totalPrice * (1 - Number(discountPourcentOrPrice) / 100),
      });
    } else if (input === InputId.secondDiscountPrice) {
      setDiscount({
        secondDiscountPourcent:
          ((totalPrice - Number(discountPourcentOrPrice)) / totalPrice) * 100,
        secondDiscountPrice: Number(discountPourcentOrPrice),
      });
    }
  };

  return (
    <form>
      <div className="invoice-number">#458655</div>
      <div className="invoice-container">
        <header className="invoice-header">
          <div>
            <h5>Nom de l'entreprise</h5>
            <p>STAGRIC</p>
            <h5>Devis fait par </h5>
            <p>{`${beorder.users_permissions_user?.data.attributes?.lastname} ${beorder?.users_permissions_user?.data.attributes?.firstname}`}</p>
          </div>
          <div className="company-details">
            <div>
              <h5>Nom de l'entreprise du client</h5>
              <p>Société : {beorder.customer?.data?.attributes.company}</p>
              <p>Adresse : {beorder.customer?.data?.attributes?.adress}</p>
              <p>N° Siret : {beorder.customer?.data?.attributes?.siret}</p>
              <p>
                N° Intracomunautaire :{" "}
                {beorder.customer?.data?.attributes?.nintracomunautaire}
              </p>
            </div>
            <button>
              <Link to={`/FrontIndex/product/${idlist}`}>
                <p className="btn btn-primary">Ajouter un article</p>
              </Link>
            </button>
          </div>
        </header>
        <section className="company-client-info">
          <div style={{ width: "100%" }}>
            <EstimateBeorder
              beorderData={beorder}
              quantitys={quantitys}
              setQuantitys={setQuantitys}
              price={price}
              setPrice={setPrice}
            />
          </div>
        </section>
        <section className="table-grid">
          <div className="cell header">Total</div>
          <div></div>
          <div className="cell">{totalPrice}€</div>

          <div className="cell">Première réduction</div>
          <div className="cell">
            <input
              type="text"
              value={`${discount.firstDiscountPourcent || 0}%`}
              onChange={(event) => {
                countDiscount(
                  event.target.value.replace("%", ""),
                  InputId.firstDiscountPourcent
                );
              }}
            />
          </div>
          <div className="cell">
            <input
              type="text"
              value={`${discount.firstDiscountPrice || 0}€`}
              onChange={(event) => {
                countDiscount(
                  event.target.value.replace("€", ""),
                  InputId.firstDiscountPrice
                );
              }}
            />
          </div>

          <div className="cell">Deuxième réduction</div>
          <div className="cell">
            <input
              type="text"
              value={`${discount.secondDiscountPourcent || 0}%`}
              onChange={(event) => {
                countDiscount(
                  event.target.value.replace("%", ""),
                  InputId.secondDiscountPourcent
                );
              }}
            />
          </div>
          <div className="cell">
            <input
              type="text"
              value={`${discount.secondDiscountPrice || 0}€`}
              onChange={(event) => {
                countDiscount(
                  event.target.value.replace("€", ""),
                  InputId.secondDiscountPrice
                );
              }}
            />
          </div>

          <div className="cell header">Prix total</div>
          <div className="cell"></div>
          <div className="cell">10€</div>
        </section>
        <footer className="invoice-footer">
          <button>Exporter Le Devis En PDF</button>
          <button>Générer Une Commande Constructeur</button>
          <input
            className="invoice-footer-button"
            type="button"
            value="Générer Une Commande"
            onClick={() => updateQuantity(quantitys)}
          />
        </footer>
      </div>
    </form>
  );
}
export default EstimateTemplate;
