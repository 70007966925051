import React, { useContext } from "react";
import AuthContext from "./AuthContext";
import { useHistory, Navigate, useNavigate, Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ children }) => {

    const { isAuthenticated } = useContext(AuthContext);
    const { location } = useNavigate()
    

    return isAuthenticated ? children : <Navigate to='/' />



}

export default PrivateRoute;