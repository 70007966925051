import ListProductbis from './products/ListProductbis'
import ProductGridApibis from './products/ProductGridApibis'
import ProductGridApibisun from './products/ProductGridApibisun'
import ProductGridApifinalbisun from './products/ProductGridApifinalbisun'
import ProductGridApiFinalCreatebis from './products/ProductGridApiFinalCreatebis'
import Log from './services/Log'
import OptionManagement from './option/OptionManagement'
import OptionList from './option/OptionList'
import OptionEdit from './option/OptionEdit'
import ProductGridApiEditfinalbis from './products/ProductGridApiEditfinalbis'
import FinalGridList from './products/FinalGridList'
import FinalGridListun from './products/FinalGridListun'
import GridProductbis from './products/GridProductbis'
import FinalCreateList from './products/FinalCreateList'
import FinalCreateListun from './products/FinalCreateListun'
import FinalListEdit from './products/FinalListEdit'
import FinalListEditun from './products/FinalListEditun'
import GridProductEditbis from './products/GridProductEditbis'
import GridProductEditbisun from './products/GridProductEditbisun'
import ProductGridApiEditfinalbisun from './products/ProductGridApiEditfinalbisun'
import OrderTracking from './order/OrderTracking'
import * as url from './include/var.js'
import React, { useState } from 'react'
import AuthApi from './services/AuthApi'
import CustomerContact from './customer_contact/CustomerContact'
import CustomerEdit from './customer_contact/CustomerEdit'
import CustomerForm from './customer_contact/CustomerForm'
import UserContact from './user_contact/UserContact'
import UserEdit from './user_contact/UserEdit'
import UserForm from './user_contact/UserForm'
import PrivateRoute from './services/PrivateRoute'
import AuthContext from './services/AuthContext'
import FrontIndex from './front/FrontIndex'
import FrontFinishList from './front/FrontFinishList'
import EstimateTemplate from './estimate_front/EstimateTemplate'
import EstimateApiFront from './estimate_front/EstimateApiFront'
// import EstimateList from './estimate_front/EstimateList'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom"
import Navi from './include/Navi'
import './App.css'
import FrontProduct from './front/FrontProduct'


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(AuthApi.isAuthenticated)
  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      setIsAuthenticated
    }}
    >

      <div className="App">
        <Router>
          <Routes>
          <Route path={url.product_grid_editfinal_bis_un + '/:idlist/:idpic/:idfinal'} element={<PrivateRoute><ProductGridApiEditfinalbisun /></PrivateRoute>} />
            <Route path={url.grid_edit_productbis + '/:idproductedit/:idpic/:idforg'} element={<PrivateRoute><GridProductEditbis /></PrivateRoute>} />
            <Route path={url.grid_edit_productbisun + '/:idproductedit/:idpic'} element={<PrivateRoute><GridProductEditbisun /></PrivateRoute>} />
            <Route path={url.final_create_list + '/:idlist'} element={<PrivateRoute><FinalCreateList /></PrivateRoute>} />
            <Route path={url.final_create_listun + '/:idlist'} element={<PrivateRoute><FinalCreateListun /></PrivateRoute>} />
            <Route path={url.final_list_edit + '/:idlist'} element={<PrivateRoute><FinalListEdit /></PrivateRoute>} />
            <Route path={url.final_list_editun + '/:idlist'} element={<PrivateRoute><FinalListEditun /></PrivateRoute>} />
            <Route path={url.product_grid_create_bis + '/:idlist'} element={<PrivateRoute><ProductGridApibis /></PrivateRoute>} />
            <Route path={url.final_grid_list + '/:idlist'} element={<PrivateRoute><FinalGridList /></PrivateRoute>} />
            <Route path={url.final_grid_listun + '/:idlist'} element={<PrivateRoute><FinalGridListun /></PrivateRoute>} />
            <Route path={url.product_grid_create_bis_un} element={<PrivateRoute><ProductGridApibisun /></PrivateRoute>} />
            <Route path={url.product_grid_create_bis_final + '/:idlist'} element={<PrivateRoute><ProductGridApiFinalCreatebis /></PrivateRoute>} />
            <Route path={url.order_tracking} element={<PrivateRoute><OrderTracking /></PrivateRoute>} />
            <Route path={url.product_grid_edit_bis_final + '/:idlist/:idpic/:idfinal/:idtest'} element={<PrivateRoute><ProductGridApiEditfinalbis /></PrivateRoute>} />
            <Route path={url.product_grid_createfinal_bis_un} element={<PrivateRoute><ProductGridApifinalbisun /></PrivateRoute>} />
            <Route path={url.grid_product_bis + '/:idlist'} element={<PrivateRoute><GridProductbis /></PrivateRoute>} />
            <Route path={url.navi} element={<PrivateRoute><Navi /></PrivateRoute>} />
            <Route path='/ListProductbis/:idlist' element={<PrivateRoute><ListProductbis /></PrivateRoute>} />
            <Route path='/ListProductbis' element={<PrivateRoute><ListProductbis /></PrivateRoute>} />
            <Route path={url.option_management} element={<PrivateRoute><OptionManagement /></PrivateRoute>} />
            <Route path={url.option_list} element={<PrivateRoute><OptionList /></PrivateRoute>} />

            {/* Customer */}
            <Route path={url.customer_contact} element={<PrivateRoute><CustomerContact /></PrivateRoute>} />
            <Route path={url.customer_form} element={<PrivateRoute><CustomerForm /></PrivateRoute>} />
            <Route path={url.customer_edit + '/:idcust'} element={<PrivateRoute><CustomerEdit /></PrivateRoute>} />

            {/* User */}
            <Route path={url.user_contact} element={<PrivateRoute><UserContact /></PrivateRoute>} />
            <Route path={url.user_form} element={<PrivateRoute><UserForm /></PrivateRoute>} />
            <Route path={url.user_edit + '/:idcust'} element={<PrivateRoute><UserEdit /></PrivateRoute>} />

            {/* Front */}
            <Route path={url.front_index + '/:idlist'} element={<PrivateRoute><FrontIndex /></PrivateRoute>} />
            <Route path={url.front_index} element={<PrivateRoute><FrontIndex /></PrivateRoute>} />
            <Route path={url.front_finish_list} component={<PrivateRoute><FrontFinishList /></PrivateRoute>} />
            {/* <Route path={url.front_product + ':idlist'} element={<PrivateRoute><FrontProduct /></PrivateRoute>} /> */}

            {/* Estimate Front */}
            <Route path={url.estimate_template + '/:idlist'} element={<PrivateRoute><EstimateTemplate /></PrivateRoute>} />
            <Route path={url.estimate_api_front} element={<PrivateRoute><EstimateApiFront /></PrivateRoute>} />
            {/* <Route path={url.estimate_list} element={<PrivateRoute><EstimateList /></PrivateRoute>} /> */}

            <Route exact path={url.log} element={<Log />} />
            <Route path={url.option_edit + '/:idlisttwoedit/:idpic'} element={<PrivateRoute><OptionEdit /></PrivateRoute>} />
            <Route
              path='/products'
              element={
                <PrivateRoute>
                  <ListProductbis />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </div>

    </AuthContext.Provider>
  )
}

export default App
