import React, { useState, useEffect, useReducer } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import {  Link } from "react-router-dom"

function CustomerGrid() {
    const [posts, setPosts] = useState()
    const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        axios.get(url.api_url + '/api/customers?populate=*&sort=id:desc')
            .then(res => {
                setPosts(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [reducerValue])

    async function handleUpdate(e, id) {
        const data = {
            "data": {
                state: e.target.checked
            }
        }
        try {
        axios.put(url.api_url + '/api/customers/' + id, data)
        .then(res => {
            forceUpdate()
            // console.log(res)
        })
        } catch (err) {
            console.log(err)
        }
    }

    function deleteRow(id) {
        console.log(id)
        axios.delete(url.api_url + `/api/orders/` + id)
        setPosts(
            posts.filter((post) => {
                return post.id !== id;
            })
        );
    }

    return (
        <div>
            {posts?.map((post) => (
                <div className='customer__table' key={post.id}>
                    {console.log(post)}
                    <p>{post.attributes.firstname}</p>
                    <p>{post.attributes.lastname}</p>
                    <p>{post.attributes.email}</p>
                    <p>{post.attributes.company}</p>
                    <p>{post.attributes.phonenumber}</p>
                    <label className="switch">
                    <input className="form-check-input form-switch" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={(e) => handleUpdate(e, post.id)}  checked={post.attributes.state} />
                        <span className="slider round"></span>
                    </label>
                    <p onClick={() => deleteRow(post.id)} className="btn btn-danger">Supprimer</p>
                    <Link to={url.customer_edit + '/' + post.id } ><p className="btn btn-info">Éditer</p></Link>
                </div>
            ))}
        </div>
    )
}

export default CustomerGrid