import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
///import Test from './Test'
//import Navi from './include/Navi'
// import Head from '../src/include/Head'
// import Foot from '../src/include/Foot'
//import Grid from './Grid'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
