import React, { Component } from 'react'


class Foot extends Component {
    render () {
	return (
	    <div className='footer__site'>
	      <p>© Copyright 2022 MG.</p>
	    </div>
	)
    }    
}

export default Foot
