import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import Select from 'react-select/async'
import JSONPretty from "react-json-pretty";
import {
    BrowserRouter as Router,
    Link,
    useParams
} from "react-router-dom"
import Navi from '../include/Navi'
import Foot from '../include/Foot'
import Head from '../include/Head'
import { withRouter } from 'react-router'



function FinalCreateListun({ evtId }) {
    const [posts, setPosts] = useState([])
    const { idlist } = useParams()
    const [message, setMessage] = useState('')
    const [messaget, setMessaget] = useState('')
    const [results, setResults] = useState([])
    const [messageth, setMessageth] = useState('')
    const [updatesData, setUpdatesData] = useState({
        codetestun: "",
        designationtestun: "",
        crptestun: "",
        pricetestun: "",
        finalid: idlist,
        optionid: '',
    })

    function updateEdits(e) {
        const newupdate = { ...updatesData }
        newupdate["optionid"] = results
        console.log(e)
        setUpdatesData(newupdate)
    }

    function updateEdit(e) {
        const newupdate = { ...updatesData }
        newupdate[e.target.id] = e.target.value
        setUpdatesData(newupdate)
    }

    const handleChange = (event) => {
        setMessage(event.target.value)
        updateEdit(event)
    }

    const handleChanget = (event) => {
        setMessaget(event.target.value)
        updateEdit(event)
    }

    const handleChangeth = (event) => {
        setMessageth(event.target.value)
        updateEdit(event)
    }

    function checkSelect(e, value) {
        const dd = e.map(e => ({ id: e.id }))
        setResults(dd)
    }

    const loadOptions = () => {
        return fetch(url.api_url + '/api/option-managements?&sort=refoption:asc')
            .then((res) => res.json())
            .then((data) => data.data);
    }

    const handleSubmit = async (e) => {
        console.log('handleSubmit')
        e.preventDefault()

        const formData = new FormData()
        formData.append('ref', 'api::finallistun.finallistun') // link with my table
        formData.append('refId', evtId)
        //formData.append('field', 'picoption') // the row
        if (message.trim().length !== 0 && messaget.trim().length !== 0 &&
            messageth.trim().length !== 0) {
            const res2 = axios.post(url.api_url + `/api/finallistuns/`, {
                "data": {
                    codetestun: updatesData.codetestun,
                    designationtestun: updatesData.designationtestun,
                    crptestun: updatesData.crptestun,
                    pricetestun: updatesData.pricetestun,
                    "finalun": {
                        id: updatesData.finalid,
                    },
                    "option_managements":
                        results
                }
            }
            )
            if (res2.ok) {
                console.log(res2)
                console.log('res.ok')
                console.log('res', res2)
            }
            alert("la fiche a été générée")
        } else {
            alert("Un des champs n'a pas été rempli")
        }
    }

    return (
        <div class="page-container">
            <Navi />
            <div class="main-content">
                <Head />
                <div class="main-content-inner">
                    <div class="col-lg-6 mt-5">
                        <div class="card">
                            <div class="card-body">
                                <form onSubmit={handleSubmit}>

                                    <div class="single-table">

                                        <div className="product__list__two">

                                            <div className='tableform__color option__table__two'>
                                                <p>CODE</p>
                                                <p>DÉSIGNATION</p>
                                                <p>CRP.</p>
                                                <p>PRIX</p>
                                            </div>
                                            <div className='option__table__two'>
                                                <input className="option__table__two__input" onChange={(e) => handleChange(e)} id="codetestun" value={updatesData.codetestun} type="text" placeholder={posts && posts.codetestun ? posts.codetestun : null} />
                                                <textarea className='option__table__two__textarea' onChange={(e) => handleChanget(e)} id="designationtestun" value={updatesData.designationtestun} type="text" placeholder={posts && posts.designationtestun ? posts.designationtestun : null} />
                                                <input className="option__table__two__input" onChange={(e) => handleChangeth(e)} id="crptestun" value={updatesData.crptestun} type="text" placeholder={posts && posts.crptestun ? posts.crptestun : null} />
                                                <input className="option__table__two__input" onChange={(e) => handleChangeth(e)} id="pricetestun" value={updatesData.pricetestun} type="text" placeholder={posts && posts.pricetestun ? posts.pricetestun : null} />
                                                <Select
                                                    cacheOptions
                                                    defaultOptions
                                                    isMulti
                                                    loadOptions={loadOptions}
                                                    getOptionLabel={(e) => e.attributes.refoption}
                                                    getOptionValue={(e) => e.id}
                                                    onChange={(e) => checkSelect(e, "optionid")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-5">
                                        <div className="card TableForm__BottomButton">
                                            <div className="card-body">
                                                <input onClick={(e) => updateEdits(e)} type='submit' value='Enregister' className='btn btn-primary mb-3' />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Foot />
        </div>
    )
}

export default FinalCreateListun