import React, { useState, useEffect, useReducer } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import './FrontOption.css'
import './FrontFinishun.css'
import {
    Link,
    useParams
} from "react-router-dom"

function FrontFinishun({ idlistpic }) {
    const [postsun, setPostsun] = useState()
    const [showExemple, setShowExemple] = useState(false);
    // const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);


    useEffect(() => {
        axios.get(url.api_url + '/api/testuns?populate=*')
            .then(res3 => {
                setPostsun(res3.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const handleImageClick = () => {
        setShowExemple(true);
    }

    return (
        <div>

            {postsun?.map((postun) => (
                <div key={postun.id}>
                    
                    {postun.id == idlistpic ? <img className="front__centering__img" src={url.api_url + postun.attributes.pictest.data.attributes.url} alt="pic of products" /> : null}
                </div>
            ))}
        </div>
    )
}

export default FrontFinishun