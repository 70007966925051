import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import {
    Link,
    useParams
} from "react-router-dom"

function GridProduct() {
    const [posts, setPosts] = useState()
    const [postsun, setPostsun] = useState()
    const { idlist } = useParams()

    useEffect(() => {
        axios.get(url.api_url + '/api/tests?populate=*')
            .then(res => {
                setPosts(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })

    }, [])

    useEffect(() => {
        axios.get(url.api_url + '/api/testuns?populate=*')
            .then(res3 => {
                setPostsun(res3.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])



    function deleteRow(id, idpic, idfinal) {
        console.log(id)
        axios.delete(url.api_url + `/api/tests/` + id)
        axios.delete(url.api_url + `/api/finals/` + idfinal)
        axios.delete(url.api_url + `/api/upload/files/` + idpic)
        setPosts(
            posts.filter((post) => {
                return post.id !== id;
            })
        );
    }

    function deleteRowun(id, idpic, idfinal) {
        console.log(id)
        axios.delete(url.api_url + `/api/testuns/` + id)
        axios.delete(url.api_url + `/api/finaluns/` + idfinal)
        axios.delete(url.api_url + `/api/upload/files/` + idpic)
        setPostsun(
            postsun.filter((postun) => {
                return postun.id !== id;
            })
        );
    }

    return (
        <div>
            {idlist == undefined ? <Link to={url.product_grid_create_bis_un} ><p className='btn btn-primary'>Créer</p></Link> : <Link to={url.product_grid_create_bis + '/' + idlist} ><p className='btn btn-primary'>Créer</p></Link>}
            {idlist == undefined ? <Link to={url.product_grid_createfinal_bis_un} ><p className='btn btn-outline-info space__button'>Dernier Produit</p></Link> : <Link to={url.product_grid_create_bis_final + '/' + idlist} ><p className='btn btn-outline-info space__button'>Dernier Produit</p></Link>}
            {/* {idlist == undefined ? <Link to={url.product_grid_create_bis_un} ><p className='btn btn-primary'>Créer</p></Link> : <Link to={url.product_grid_create_bis + '/' + idlist} ><p className='btn btn-primary'>Créer</p></Link>} */}
            <div className="App product__grid">
                {idlist !== undefined ?
                    posts?.map((post) => (

                        //trouver solution pour supprimer la forgenkey et l'arbre

                        <div className={post.attributes.idtest == idlist ? 'card card-bordered' : 'template'} key={post.id}>
                            <div className='icons__right'>
                                {post.attributes.final === false ? <Link to={url.grid_edit_productbis + '/' + post.id + '/' + post.attributes.pictest.data.id + '/' + post.attributes.idtest} ><img src={url.icon_edit} /></Link> : <Link to={url.product_grid_edit_bis_final + '/' + post.id + '/' + post.attributes.pictest.data.id + '/' + post.attributes.idfinal + '/' + post.attributes.idtest} ><img src={url.icon_edit} /></Link>}
                                {console.log(post.attributes.final)}
                                {/* <img onClick={() => deleteRow(post.id, post.attributes.pictest.data.id)} src={url.icon_delete} /> */}
                                <img onClick={() => deleteRow(post.id, post.attributes.pictest.data.id, post.attributes.idfinal)} src={url.icon_delete} />
                            </div>
                            {post.attributes.idtest == idlist ? <img className="card-img-top img-fluid" src={url.api_url + post.attributes.pictest.data.attributes.url} alt="pic of products" /> : null}
                            {post.attributes.idtest == idlist ? <h5 className='title'>{post.attributes.titletest}</h5> : null}

                            {/* {post.attributes.final == false  ? <Link to={'/ListProductbis/' + post.id} ><p className="btn btn-primary">Regarder</p></Link> : <Link to={url.product_grid_edit_bis_final + '/' +  post.id + '/' + post.attributes.pictest.data.id + '/' + post.attributes.idfinal + '/' + post.attributes.idtest} ><p className="btn btn-primary">Regarder</p></Link>} */}
                            {post.attributes.final == false ? <Link to={'/ListProductbis/' + post.id} ><p className="btn btn-primary">Regarder</p></Link> : <Link to={url.final_grid_list + '/' + post.attributes.idfinal} ><p className="btn btn-primary">Regarder</p></Link>}
                        </div>
                    ))
                    :
                    postsun?.map((postun) => (
                        <div className="card card-bordered" key={postun.id}>
                            <div className='icons__right'>
                                {postun.attributes.final === false ? <Link to={url.grid_edit_productbisun + '/' + postun.id + '/' + postun.attributes.pictest.data.id} ><img src={url.icon_edit} /></Link> : <Link to={url.product_grid_editfinal_bis_un + '/' + postun.id + '/' + postun.attributes.pictest.data.id + '/' + postun.attributes.idfinalun} ><img src={url.icon_edit} /></Link>}
                                <img onClick={() => deleteRowun(postun.id, postun.attributes.pictest.data.id, postun.attributes.idfinalun)} src={url.icon_delete} />
                            </div>
                            <img className="card-img-top img-fluid" src={postun.attributes.pictest.data.attributes && postun.attributes.pictest.data.attributes.url ? url.api_url + postun.attributes.pictest.data.attributes.url : null} alt="pic of products" />
                            <h5 className='title'>{postun.attributes && postun.attributes.titletest ? postun.attributes.titletest : null}</h5>
                            {postun.attributes.final == false ? <Link to={'/ListProductbis/' + postun.id} ><p className="btn btn-primary">Regarder</p></Link> : <Link to={url.final_grid_listun + '/' + postun.attributes.idfinalun} ><p className="btn btn-primary">Regarder</p></Link>}
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default GridProduct
