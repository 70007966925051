import { useEffect, useState } from "react";
import axios from "axios";
import * as url from "../include/var.js";
import "./EstimateOption.css";
import "./EstimateTemplate.css";

export const DataName = {
    optionData: "option-managements",
    finalUnData: "finaluns",
    finalData: "finals",
    finalListData: "finallists",
  };

function EstimateBeorder(props) {
  const [concatData, setConcatData] = useState();
  const [concatDataList, setConcatDataList] = useState("");
  const [concatDataFinalList, setConcatDataFinalList] = useState();
  const { quantitys, setQuantitys } = props;

  useEffect(() => {
    axios
      .get(`${url.api_url}/api/${DataName.optionData}?populate=*`)
      .then((response) => {
        setConcatData(response.data.data);
      })
      .catch((err) => console.err(err));
  }, []);

  useEffect(() => {
    axios
      .get(`${url.api_url}/api/${DataName.finalListData}?populate=*`)
      .then((response) => {
        setConcatDataList(response.data.data);
      })
      .catch((err) => console.err(err));
  }, []);

  useEffect(() => {
    axios
      .get(`${url.api_url}/api/${DataName.finalData}?populate=*`)
      .then((response) => {
        setConcatDataFinalList(response.data.data);
      })
      .catch((err) => console.err(err));
    console.log(quantitys);
  }, []);

  const dataDisplay = (data, name) => {
    let concat;
    if (data && name === DataName.optionData) {
      props.beorderData.option_managements?.data?.map((optionMap) => {
        if (parseInt(data.attributes?.idoption) === optionMap.id) {
          concat = {
            id: data.id,
            refoption: optionMap.attributes.refoption,
            designationoption: optionMap.attributes.designationoption,
            priceoption: optionMap.attributes.priceoption,
            numberoption: data.attributes.numberoption,
            idOption: optionMap.id,
          };
        }
      });
    } else if (data && name === DataName.finalListData) {
      props.beorderData.finallists?.data?.map((finalListMap) => {
        if (parseInt(data.attributes.idfinallist) === finalListMap.id && concatDataList) {
          concatDataList?.map((finalListData) => {
            if (finalListData.id === parseInt(data.attributes.idfinallist)) {
              concatDataFinalList?.map((finalData) => {
                if (finalListData.attributes.final.data.id === finalData.id) {
                  concat = {
                    id: data.id,
                    crptest: finalListMap.attributes.crptest,
                    designationtest: finalListMap.attributes.designationtest,
                    descriptionfinallist:
                      finalListData.attributes.final.data.attributes
                        .descriptiontest,
                    pricefinallist: finalListMap.attributes.pricetest,
                    numberfinallist: data.attributes.numberfinallist,
                    idFinal: finalListData.attributes.final.data.id,
                    pictfinallist:
                      finalData.attributes.pictest.data.attributes.formats.small
                        .url,
                  };
                }
              });
            }
          });
        }
      });
    }
    return concat;
  };

  const picOption = (name, id, data) => {
    const apiFormat = concatData?.find((ids) => ids.id === id)?.attributes
      .picoption.data.attributes;
    if (name === DataName.optionData) {
      return apiFormat?.formats
        ? apiFormat.formats.thumbnail?.url
        : apiFormat?.url;
    }
  };
  const displayBeorder = (data) => {
    const OptionDisplay = () => {
      return (
        <>
          <img
            src={
              url.api_url +
              picOption(
                DataName.optionData,
                dataDisplay(data, DataName.optionData)?.idOption,
                data
              )
            }
            alt="Option"
          />
          <p>{dataDisplay(data, DataName.optionData)?.designationoption}</p>
          <p>{dataDisplay(data, DataName.optionData)?.refoption}</p>
          <p>{dataDisplay(data, DataName.optionData)?.numberoption}</p>
          <p>{dataDisplay(data, DataName.optionData)?.priceoption}</p>
        </>
      );
    };

    const FinalDisplay = () => {
      return (
        <>
          <img
            src={
              url.api_url +
              dataDisplay(data, DataName.finalListData)?.pictfinallist
            }
            alt="FinalListUn"
          />
          <p>
            {dataDisplay(data, DataName.finalListData)?.descriptionfinallist}
          </p>
          <p>{dataDisplay(data, DataName.finalListData)?.crptest}</p>
          <p>{dataDisplay(data, DataName.finalListData)?.pricefinallist}</p>
        </>
      );
    };

    return (
      <>
        {dataDisplay(data, DataName.optionData) ? <OptionDisplay /> : null}
        {/*dataDisplay(data, DataName.finalUnData) ? <FinalUnDisplay /> : null*/}
        {dataDisplay(data, DataName.finalListData) ? <FinalDisplay /> : null}
      </>
    );
  };

  const handleQuantity = (e, name, id) => {
    let updatedValue = { quantity: e.target.value, name: name, id: id };

    if (quantitys) {
      setQuantitys((oldQuantity) => {
        const exists = oldQuantity.some((item) => item.id === updatedValue.id);

        if (exists) {
          return oldQuantity?.map((item) =>
            item.id === updatedValue.id
              ? { ...item, quantity: updatedValue.quantity }
              : item
          );
        } else {
          return [...oldQuantity, updatedValue];
        }
      });
    } else {
      setQuantitys([updatedValue]);
    }
  };

  useEffect(() => {
    props.beorderData.beorderoptions?.data?.map((quantityMap) => {
      setQuantitys({
        id: quantityMap.id,
        quantity: quantityMap.attributes.numberoption,
        name: DataName.optionData,
      });
    });
    props.beorderData.beorderfinallists?.data?.map((quantityMap) => {
        setQuantitys({
          id: quantityMap.id,
          quantity: quantityMap.attributes.numberfinallist,
          name: DataName.finalListData,
        });
      });
  }, []);

  return (
    <>
    {props.beorderData.beorderfinallists?.data?.map((beorderFinal) => (
        <div key={beorderFinal.id}>
          <section className="invoice-details" key={beorderFinal.id}>
            {beorderFinal ? displayBeorder(beorderFinal) : null}
            <input
              type="text"
              name="FinalListQuantity"
              defaultValue={
                quantitys?.id === beorderFinal.id
                  ? quantitys?.quantity
                  : beorderFinal.attributes.numberfinallist
              }
              onChange={(e) =>
                handleQuantity(
                  e,
                  DataName.finalListData,
                  dataDisplay(beorderFinal, DataName.finalListData)?.id
                )
              }
            />
          </section>
        </div>
      ))}
      {props.beorderData.beordertestuns?.data?.map((beorderUn) => (
        <div key={beorderUn.id}>
          <section className="invoice-details" key={beorderUn.id}>
            {beorderUn ? displayBeorder(beorderUn) : null}
          </section>
        </div>
      ))}
      {props.beorderData.beorderoptions?.data?.map((option) => (
        <div key={option.id}>
          <section className="invoice-details" key={option.id}>
            {option ? displayBeorder(option) : null}
            <input
              type="text"
              name="optionQuantity"
              defaultValue={
                quantitys?.id === option.id
                  ? quantitys?.quantity
                  : option.attributes.numberoption
              }
              onChange={(e) =>
                handleQuantity(
                  e,
                  DataName.optionData,
                  dataDisplay(option, DataName.optionData)?.id
                )
              }
            />
          </section>
        </div>
      ))}
    </>
  );
}
export default EstimateBeorder;
