import axios from "axios";
import * as url from "../include/var.js";
import { DataName } from "./EstimateBeorder";

export const updateQuantity = (quantityValues) => {
  console.log(quantityValues, '======>quantityValues.name' )
  const sendDataName =
    quantityValues.name === DataName.optionData
      ? "beorder-options"
      : "beorderfinallists";
  if (quantityValues && quantityValues.length > 0) {
    quantityValues.forEach((item) => {
      console.log(item.name)
      const requestBody = {
        data:
          item.name === DataName.optionData
            ? {
                numberoption: item.quantity,
              }
            : { numberfinallist: item.quantity},
      };

      axios
        .put(`${url.api_url}/api/${sendDataName}/${item.id}`, requestBody, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(
            `Mise à jour réussie pour`,
            response.data
          );
        })
        .catch((error) => {
          console.error(
            `Erreur lors de la mise à jour de l'ID ${item.id} :`,
            error
          );
        });
    });
  } else {
    console.error("Aucune donnée à mettre à jour.");
  }
};
