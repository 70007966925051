import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useField } from 'formik';
import * as url from '../include/var.js'
import './FrontFinalList.css'
import {
    Link,
    useParams
} from "react-router-dom"

function FrontFinalList({ foldedStates, toggleFold, frontFinalListid, selectedItems, setSelectedItems }) {
    const [finalLists, setFinalLists] = useState()
    // const [foldedStates, setFoldedStates] = useState({});
    const [nestedFoldedStates, setNestedFoldedStates] = useState({});
    const [field, meta] = useField('finalName');



    useEffect(() => {
        axios.get(url.api_url + '/api/finallists?populate=*&sort=id:desc')
            .then(res => {
                setFinalLists(res.data.data)
                const initialFoldedStates = Object.fromEntries(res.data.data.map(item => [item.id, true]));
                const initialNestedFoldedStates = Object.fromEntries(res.data.data.map(item => [`${item.id}-nested`, true]));
                // setFoldedStates(initialFoldedStates);
                setNestedFoldedStates(initialNestedFoldedStates);

            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const toggleNestedFold = (e, id) => {
        e.stopPropagation()
        setNestedFoldedStates(prevState => ({
            ...prevState,
            [`${id}-nested`]: !prevState[`${id}-nested`]
        }));
    };

    const handleClick = (post) => {
        // setSelectedPost(post);
        toggleFold(post.id);
    }

    const handleCheckboxClick = (e, id, isProduct) => {
        e.stopPropagation();

        setSelectedItems(prevState => {
            // Clone de l'état précédent
            const newSelection = { ...prevState };

            // Sélectionner ou désélectionner l'élément
            if (isProduct == "product") {
                if (e.target.checked) {
                    newSelection.finallists.push(id);
                } else {
                    newSelection.finallists = newSelection.finallists.filter(item => item !== id);
                }
            } else if (isProduct == "option") {
                if (e.target.checked) {
                    newSelection.option_managements.push(id);
                    
                } else {
                    newSelection.options_management = newSelection.option_managements.filter(item => item !== id);
                }
            }
            return newSelection;
        });
    };

    return (
        <div>
            {finalLists?.map((finalList) => (
                <div className={finalList.attributes.final.data && frontFinalListid == finalList.attributes.final.data.id ? 'front__list__open' : 'front__list__none'} key={finalList.id} onClick={() => toggleFold(finalList.id)}>
                    {finalList.attributes.final.data && frontFinalListid == finalList.attributes.final.data.id ? <p>{finalList.attributes.codetest}</p> : null}
                    {foldedStates[finalList.id] ? null : (
                        <>
                            {finalList.attributes.final.data && frontFinalListid == finalList.attributes.final.data.id ?
                                <input
                                    {...field} 
                                    type="checkbox"
                                    name='finallists'
                                    value={finalList.id}
                                    id={finalList.id}
                                    onClick={(e) => handleCheckboxClick(e, finalList.id, "product")}
                                    checked={selectedItems.finallists.includes(finalList.id)} /> : null}
                            {meta.touched && meta.error ? (
                                <div className="error">{meta.error}</div>
                            ) : null}
                            {finalList.attributes.final.data && frontFinalListid == finalList.attributes.final.data.id ? <p>{finalList.attributes.designationtest}</p> : null}
                            <br />
                            {finalList.attributes.final.data && frontFinalListid == finalList.attributes.final.data.id ? <p>{finalList.attributes.pricetest}</p> : null}
                            <div className='front__option__bubble' onClick={(e) => toggleNestedFold(e, finalList.id)}>
                                {finalList.attributes.final.data && frontFinalListid == finalList.attributes.final.data.id ? <p className='front__text__left'>Options</p> : null}
                                {nestedFoldedStates[`${finalList.id}-nested`] ? null : (
                                    <>
                                        {finalList.attributes.option_managements.data?.map((optionList) => (
                                            <div key={optionList.id}>
                                                {finalList.attributes.final.data && frontFinalListid == finalList.attributes.final.data.id ?
                                                    <input
                                                        {...field}
                                                        type="checkbox"
                                                        name='option_managements'
                                                        value={optionList.id} id={optionList.id}
                                                        onClick={(e) => handleCheckboxClick(e, optionList.id, "option")}
                                                        checked={selectedItems.option_managements.includes(optionList.id)} /> : null}
                                                <p>{optionList.attributes.designationoption}</p>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>

                        </>
                    )}
                </div>
            ))}
        </div>
    )
}

export default FrontFinalList