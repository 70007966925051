import React, { useState, useEffect } from 'react'
import axios from 'axios'
import JSONPretty from "react-json-pretty"
import Select from 'react-select/async'
import * as url from '../include/var.js'
import {
  BrowserRouter as Router,
  Link,
  useParams
} from "react-router-dom"
import Navi from '../include/Navi'
import Foot from '../include/Foot'
import Head from '../include/Head'
import { withRouter } from 'react-router'



function ProductGridApiEditfinalbis({ evtId }) {
  const [image, setImage] = useState(null)
  const [isClick, setIsClick] = useState(false)
  const [posts, setPosts] = useState([])
  const [results, setResults] = useState([])
  const { idlist } = useParams()
  const { idpic } = useParams()
  const { idfinal } = useParams()
  const { idtest } = useParams()
  const [message, setMessage] = useState('')
  const [messaget, setMessaget] = useState('')
  const [messageth, setMessageth] = useState('')
  const [messagef, setMessagef] = useState('')
  const [firstData, setFirstData] = useState({
    titletest: "",
    final: true,
  })
  const [updatesData, setUpdatesData] = useState({
    titletest: "",
    descriptiontest: "",
    codetest: "",
    designationtest: "",
    optionid: "",
    id: idlist,
  })

  function updateEdits(e, value) {
    setPosts(e)
    console.log(e.id)
    const newupdate = { ...updatesData }
    newupdate[value] = e.id
    setUpdatesData(newupdate)
  }

  function updateEdit(e) {

    const newupdate = { ...updatesData }
    const newupdates = { ...firstData }
    newupdate[e.target.id] = e.target.value
    newupdates[e.target.id] = e.target.value
    setUpdatesData(newupdate)
    setFirstData(newupdates)
  }

  function checkSelect(e, value) {
    const dd = e.map(e => ({ id: e.id }))
    setResults(dd)
  }

  function updateEdits(e) {
    const newupdate = { ...updatesData }
    newupdate["optionid"] = results
    console.log(e)
    setUpdatesData(newupdate)
  }

  const handleClick = () => {
    setIsClick(current => !current);
  }

  const handleChange = (event) => {
    setMessage(event.target.value)
    updateEdit(event)
  }

  const handleChanget = (event) => {
    setMessaget(event.target.value)
    updateEdit(event)
  }

  const handleChangeth = (event) => {
    setMessageth(event.target.value)
    updateEdit(event)
  }

  const handleChangef = (event) => {
    setMessagef(event.target.value)
    updateEdit(event)
  }

  useEffect(() => {
    axios.get(url.api_url + '/api/finals/' + idfinal)
      .then(response => {
        setUpdatesData({
          ...updatesData,
          titletest: response.data.data.attributes.titletest,
          descriptiontest: response.data.data.attributes.descriptiontest,
        });
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    axios.get(url.api_url + '/api/tests?populate=*')
      .then(res => {
        setPosts(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const handleSubmit = async (e) => {
    console.log('handleSubmit')
    e.preventDefault()

    const formData = new FormData()
    formData.append('files', image) // the pic
    formData.append('ref', 'api::product-product.product-product') // link with my table
    formData.append('refId', evtId)
    //formData.append('field', 'picproductgroup') // the row
    if (message.trim().length !== 0 &&
      messagef.trim().length !== 0) {
      axios.post(url.api_url + '/api/upload/', formData).then(res => {
        const res2 = axios.put(url.api_url + `/api/finals/` + idfinal, {
          "data": {
            titletest: updatesData.titletest,
            descriptiontest: updatesData.descriptiontest,
            pictest: res.data[0].id,
            "option_managements":
              results
          }
        }
        ).then(res2 => {
          console.log("l'id de finaluns est " + res2.data)
          axios.put(url.api_url + `/api/tests/` + idlist, {
            "data": {
              titletest: updatesData.titletest,
              final: true,
              idtest: idtest,
              idfinal: idfinal,
              pictest: res.data[0].id,
            }
          }
          )
        })

        if (res2.ok) {
          console.log(res2)
          console.log('res.ok')
          console.log('res', res2)
          // imageUploaded()
        }
      }).catch(error => {
        console.log(error.message);
      })
      console.log("La fiche a été crée")
    } else {
      alert("Un des champs n'a pas été rempli")
    }


    //e.preventDefault()
  }

  const loadOptions = () => {
    return fetch(url.api_url + '/api/option-managements')
      .then((res) => res.json())
      .then((data) => data.data);
  }

  const handleFileChange = (e) => {
    if (e.target.files[0].name.split('.').pop() !== "png" && e.target.files[0].name.split('.').pop() !== "jpg" &&
      e.target.files[0].name.split('.').pop() !== "jpeg") {
      alert("Il y a un problème avec le format de l'image")
    } else {
      setImage(e.target.files[0])
    }
  }

  function deletePic(idpic) {
    if (image.name === 0 || message.trim().length === 0) {
      alert("Il y a un problème avec l'image ou un champ n'a pas été rempli")
    } else {
      axios.delete(url.api_url + `/api/upload/files/` + idpic)
    }
  }

  return (
    <div class="page-container">
      <Navi />
      <div class="main-content">
        <Head />
        <div class="main-content-inner">
          <div class="col-lg-6 mt-5">
            <div class="card">
              <div class="card-body">
                <form onSubmit={handleSubmit}>
                  <div className='select__space'>
                    <div class="Image">
                      <div class="icon-container">
                        <span class="ti-plus"></span>
                      </div>
                      <input className='file-plus' type='file' onChange={handleFileChange} />
                      {image ? (
                        <div class="icon-container">
                          <img class="Image__Icon" src={URL.createObjectURL(image)} alt="Thumb" />

                        </div>
                      ) : (
                        posts?.map((post) => (
                          <div class="icon-container">
                            {post.id == idlist ? <img class="Image__Icon" src={url.api_url + post.attributes.pictest.data.attributes.url} alt="Thumb" /> : null}
                          </div>
                        )))}
                    </div>
                  </div>
                  <h4 className="header-title">Titre</h4>
                  <input className="option__table__two__input" onChange={(e) => handleChange(e)} id="titletest" value={updatesData.titletest} type="text" placeholder={posts && posts.titletest ? posts.titletest : null} />
                  <h4 className="header-title">DESCRIPTIF GENERAL</h4>
                  <textarea className='option__table__two__textarea TableForm' onChange={(e) => handleChangef(e)} id="descriptiontest" value={updatesData.descriptiontest} type="text" placeholder={posts && posts.descriptiontest ? posts.descriptiontest : null} />
                  <div className="col-lg-6 mt-5">
                    <div className="card TableForm__BottomButton">
                      <div className="card-body">
                        <input onClick={(e) => updateEdits(e)} type='submit' value='Enregister' className='btn btn-primary mb-3' />
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Foot />
    </div>
  )
}


export default ProductGridApiEditfinalbis
