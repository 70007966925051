
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import {
    Link,
} from "react-router-dom"

function OrderTableTrack() {
    const [posts, setPosts] = useState()

    useEffect(() => {
        axios.get(url.api_url + '/api/orders?populate=*&sort=id:desc')
            .then(res => {
                setPosts(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })

    }, [])

    function deleteRow(id) {
        console.log(id)
        axios.delete(url.api_url + `/api/orders/` + id)
        setPosts(
            posts.filter((post) => {
                return post.id !== id;
            })
        );
    }

    return (
        <div>
            {posts?.map(post => (
                <div className='order__table' key={post.id}>
                    <Link to={url.option_list} ><p>{post.attributes.firstname}</p></Link>
                    <Link to={url.option_list} ><p>{post.attributes.lastname}</p></Link>
                    <Link to={url.option_list} ><p>{post.attributes.email}</p></Link>
                    <Link to={url.option_list} ><p>{post.attributes.company}</p></Link>
                    {/* {post.attributes.state === "Livré" ? <Link to={url.option_list} ><p className='state__size badge badge-pill badge-success'>{post.attributes.state}</p></Link> : null} */}
                    {post.attributes.state === "En Attente" ? <Link to={url.option_list} ><p className='state__size badge badge-pill badge-warrning'>{post.attributes.state}</p></Link> : null}
                    {post.attributes.state === "Annulé" ? <Link to={url.option_list} ><p className='state__size badge badge-pill badge-danger'>{post.attributes.state}</p></Link> : null}
                    {post.attributes.state === "Signé" ? <Link to={url.option_list} ><p className='state__size badge badge-pill badge-success'>{post.attributes.state}</p></Link> : null}
                    <p onClick={() => deleteRow(post.id)} className="btn btn-danger">Supprimer</p>
                    <p className="btn btn-info">Éditer</p>


                </div>
            ))}
        </div>
    )
}

export default OrderTableTrack