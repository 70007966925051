import Navi from '../include/Navi'
import UserGrid from './UserGrid'
import Foot from '../include/Foot'
import Head from '../include/Head'
import {
    Link,
} from "react-router-dom"
import * as url from '../include/var.js'



function UserContact() {

    return (
        <div>
            <div className="scren__size">
                <Head />
                <Navi />
                <div className='col-lg-12 col-md-6 mt-5'>
                    <Link to={url.user_form} ><p className="btn btn-primary">Créer</p></Link>
                </div>
                <div className="product__list">
                    <div>
                        <div className='tableform__color customer__table'>
                            <p>PRÉNOM</p>
                            <p>NOM</p>
                            <p>EMAIL</p>
                            <p>SOCIÉTÉ</p>
                            <p>TÉL</p>
                            <p>ÉTAT</p>
                        </div>
                        <UserGrid />
                    </div>
                </div>
                <Foot />
            </div>
        </div>
    )
}

export default UserContact
