import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import "./FrontIndex.css";
import FrontCient from "./FrontClient";
import FrontOption from "./FrontOption";
import FrontProduct from "./FrontProduct";
import FrontFinalList from "./FrontFinalList";
import * as url from "../include/var.js";

function FrontIndex({ evtId }) {
  const [clickClient, setClickClient] = useState(true); // Afficher le contenu de base
  const [posts, setPosts] = useState();
  const [finalLists, setFinalLists] = useState();
  const [finalId, setFinalId] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [foldedStates, setFoldedStates] = useState({});
  //Pour récuperer ce qu'il y a dans le FrontProduct
  const [selectedItems, setSelectedItems] = useState({
    customer: [],
    finallists: [],
    option_managements: [],
    testuns: [],
    finallistuns: [],
    beorderfinallists: [],
    beorderoptions: [],
    beordertestuns: [],
    users_permissions_user: [],
  });
  const { idlist } = useParams();

  const handleSelectedIdChange = (newSelectedId) => {
    setSelectedId(newSelectedId);
  };

  useEffect(() => {
    axios
      .get(url.api_url + "/api/tests?populate=*&sort=id:desc")
      .then((res) => {
        setPosts(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(url.api_url + "/api/finallists?populate=*&sort=id:desc")
      .then((res) => {
        setFinalLists(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedId !== null && posts) {
      const filteredPosts = posts.filter((post) => post.id === selectedId);
      filteredPosts.forEach((post) => {
        setFinalId(post.attributes.idfinal);
      });
    }
  }, [selectedId, posts]);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("ref", "api::beorder.beorder");
    formData.append("refId", evtId);
    const uniqueFinallists = [...new Set(selectedItems.finallists)];
    const uniqueCustomers = [...new Set(selectedItems.customer)];
    const uniqueOptionManagements = [
      ...new Set(selectedItems.option_managements),
    ];
    const uniqueTestuns = [...new Set(selectedItems.testuns)];
    const uniqueFinallistuns = [...new Set(selectedItems.finallistuns)];
    const conditionForAlert =
      uniqueFinallists.length ||
      uniqueOptionManagements.length ||
      uniqueTestuns.length ||
      uniqueFinallistuns.length;
    if (idlist && idlist !== "undefined") {
      axios
        .get(`${url.api_url}/api/beorders/${idlist}?populate=*`)
        .then((response) => {
          const existingData = response.data.data;
          const existingAllDatas = {
            option_map: existingData.attributes.option_managements.data?.map(
              (data) => data.id
            ),
            finallists: existingData.attributes.finallists.data?.map(
              (data) => data.id
            ),
            testuns: existingData.attributes.testuns.data?.map(
              (data) => data.id
            ),
            finallistuns: existingData.attributes.finallistuns.data?.map(
              (data) => data.id
            ),
            customer: existingData.attributes.customer.data?.id,
          };
          const updatedData = {
            finallists: [
              ...(existingAllDatas.finallists || []),
              ...uniqueFinallists,
            ],
            customer: uniqueCustomers.length
              ? uniqueCustomers
              : existingAllDatas.customer,
            option_managements: [
              ...(existingAllDatas.option_map || []),
              ...uniqueOptionManagements,
            ],
            testuns: [...(existingAllDatas.testuns || []), ...uniqueTestuns],
            finallistuns: [
              ...(existingAllDatas.finallistuns || []),
              ...uniqueFinallistuns,
            ],
            users_permissions_user: localStorage.id,
          };

          return axios.put(
            `${url.api_url}/api/beorders/${idlist}`,
            { data: updatedData },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        })
        .then((response) => {
          const beorderId = response.data.data.id;

          createBeorderOptions(beorderId, uniqueOptionManagements);
          createBeorderTestUn(beorderId, uniqueTestuns);
          createBeorderFinallists(beorderId, uniqueFinallists);
        })
        .catch((error) => {
          console.error("Erreur lors de la mise à jour du beorder", error);
        });
    } else {
      if (uniqueCustomers.length && conditionForAlert) {
        axios
          .post(
            `${url.api_url}/api/beorders/`,
            {
              data: {
                finallists: uniqueFinallists,
                customer: uniqueCustomers,
                option_managements: uniqueOptionManagements,
                testuns: uniqueTestuns,
                finallistuns: uniqueFinallistuns,
                users_permissions_user: localStorage.id,
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
                // 'Authorization': 'Bearer VotreTokenJWT' // Si l'authentification est nécessaire
              },
            }
          )
          .then((response) => {
            const beorderId = response.data.data.id;

            createBeorderOptions(beorderId, uniqueOptionManagements);
            createBeorderTestUn(beorderId, uniqueTestuns);
            createBeorderFinallists(beorderId, uniqueFinallists);
          })
          .catch((error) => {
            console.error("Erreur lors de la création du beorder", error);
          });
      } else {
        if (!uniqueCustomers.length && !conditionForAlert) {
          alert("Vous n'avez pas selectionné de donnée");
        } else if (!uniqueCustomers.length && conditionForAlert) {
          alert("Vous n'avez pas selectionné aucun client");
        } else if (uniqueCustomers.length && !conditionForAlert) {
          alert("Vous n'avez pas selectionné de produit");
        }
      }
    }
  };

  function createBeorderTestUn(beorderId, uniqueTestuns) {
    uniqueTestuns.forEach((item) => {
      axios
        .post(`${url.api_url}/api/beordertests`, {
          data: {
            idtestun: item.toString(), // Assurez-vous que cela correspond à votre modèle de données
            beorder: beorderId,
          },
        })
        .catch((error) =>
          console.error("Erreur lors de la création de BeorderTestun", error)
        );
    });
  }
  function createBeorderOptions(beorderId, uniqueOptionManagements) {
    uniqueOptionManagements.forEach((item) => {
      axios
        .post(`${url.api_url}/api/beorder-options`, {
          data: {
            idoption: item.toString(), // Assurez-vous que cela correspond à votre modèle de données
            beorder: beorderId,
          },
        })
        .catch((error) =>
          console.error("Erreur lors de la création de beorder-options", error)
        );
    });
  }

  function createBeorderFinallists(beorderId, uniqueFinallists) {
    uniqueFinallists.forEach((item) => {
      axios
        .post(`${url.api_url}/api/beorderfinallists`, {
          data: {
            idfinallist: item.toString(), // Assurez-vous que cela correspond à votre modèle de données
            beorder: beorderId,
          },
        })
        .catch((error) =>
          console.error(
            "Erreur lors de la création de beorderfinallists",
            error
          )
        );
    });
  }

  const toggleFold = (id) => {
    setFoldedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div className="front__background">
      <Formik
        initialValues={{}}
        // onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="front__left">
              <div className="front__left__logo">
                <img src={url.icon_logo} alt="logo" />
              </div>

              <div className="front__left__info">
                <ul>
                  <li className="front__list__open">
                    <p>Liste des clients</p>
                    <ul
                      className={
                        clickClient
                          ? "front__list__nested"
                          : "front__list__nested hidden"
                      }
                    >
                      <FrontCient
                        foldedStates={foldedStates}
                        toggleFold={toggleFold}
                        frontFinalListid={finalId}
                        setFieldValue={setFieldValue}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                      />
                    </ul>
                  </li>
                  <li className="front__list__open">
                    <p>Produits</p>
                    <FrontProduct
                      onSelectedIdChange={handleSelectedIdChange}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                    />
                  </li>
                  <li className="front__list__open">
                    <p>Options</p>
                    <FrontOption
                      foldedStates={foldedStates}
                      toggleFold={toggleFold}
                      frontFinalListid={finalId}
                      setFieldValue={setFieldValue}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="front__list__right">
              <div
                className={
                  finalId ? "front__border__end" : "front__list__nested hidden"
                }
              >
                {finalId ? (
                  <FrontFinalList
                    foldedStates={foldedStates}
                    toggleFold={toggleFold}
                    frontFinalListid={finalId}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                  />
                ) : null}
              </div>
            </div>
            <Link to={url.estimate_api_front}>
              <p className="btn btn-secondary">Devis</p>
            </Link>
            <button
              className="btn btn-secondary"
              style={{ margin: "10px" }}
              type="submit"
              onClick={handleSubmit}
            >
              Valider
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FrontIndex;
