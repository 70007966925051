import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'

function FinalListOption(id) {
    const [posts, setPosts] = useState([])

    useEffect(() => {
        axios.get(url.api_url + '/api/finallistuns?populate=*')
            .then(res => {
                setPosts(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <div>
            {posts?.map((post) => (
                <div className='test' key={post.id} >
                    {post.attributes.option_managements.data?.map((postbis) => (
                        <div key={postbis.id} >
                            {post && post.id == id.id ? <p>{postbis.attributes && postbis.attributes.refoption ? postbis.attributes.refoption : null}</p> : null}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default FinalListOption