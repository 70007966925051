import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import { findByPlaceholderText } from '@testing-library/react'
import { Link } from 'react-router-dom'
import './EstimateApiFront.css'


function EstimateApiFront() {
    const [resumeLists, setResumeLists] = useState()
    const [prixList, setPrixList] = useState()

    useEffect(() => {
        axios.get(url.api_url + '/api/beorders?populate=*')
            .then(res => {
                setResumeLists(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const calculateTotal = (idEstimate) => {
        let total = 0;
        resumeLists.forEach(resume => {
            if (resume.id === idEstimate) {
                // Vérifier que finallists existe et n'est pas vide avant de l'itérer
                if (resume.attributes.finallists && Array.isArray(resume.attributes.finallists.data)) {
                    resume.attributes.finallists.data.forEach(finalist => {
                        total += parsePrice(finalist.attributes.pricetest);
                    });
                }
                // Vérifier que option_managements existe et n'est pas vide avant de l'itérer
                if (resume.attributes.option_managements && Array.isArray(resume.attributes.option_managements.data)) {
                    resume.attributes.option_managements.data.forEach(optionManagement => {
                        total += parsePrice(optionManagement.attributes.priceoption);
                    });
                }
                if (resume.attributes.finallistuns && Array.isArray(resume.attributes.finallistuns.data)) {
                    resume.attributes.finallistuns.data.forEach(finalListUns => {
                        total += parsePrice(finalListUns.attributes.pricetestun);
                    });
                }
            }
        });
        return total;
    };
    
    const parsePrice = (priceString) => {
        let result = priceString.replace(/,/g, '.');
        result = result.replace(/ /g, '');
        result = result.replace(/[^0-9.]+/g, '');
        let number = parseFloat(result);
        return !isNaN(number) ? number : 0;
    };

    return (
        <>
            {resumeLists?.map((resumeList) => (
                <div  key={resumeList.id}>
                    <Link className='grid-name' to={url.estimate_template + '/' + resumeList.id}>
                        <p><input type='checkbox' /></p>
                        <p>{resumeList.attributes.customer.data?.attributes.company}</p>
                        <p>{calculateTotal(resumeList.id) + ' €'}</p>
                        <p>{new Date(resumeList.attributes.publishedAt).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
                        
                    </Link>
                </div>
            ))}
        </>
    )
}

export default EstimateApiFront
