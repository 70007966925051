import React, { useState, useEffect } from 'react'
import {
    Link,
    useParams
} from "react-router-dom"
import axios from 'axios'
import FinalListOptionun from './FinalListOptionun'
import * as url from '../include/var.js'

function FinalListun() {
    const [posts, setPosts] = useState()
    const { idlist } = useParams()

    useEffect(() => {
        axios.get(url.api_url + '/api/finallistuns?populate=*')
            .then(res => {
                setPosts(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    function deleteRow(id) {
        console.log(id)
        axios.delete(url.api_url + `/api/finallistuns/` + id)
        setPosts(
            posts.filter((post) => {
                return post.id !== id;
            })
        );
    }

    return (
        <div>
            {posts?.map((post) => (
                <div className='final__list__table' key={post.id} >
                    {post.attributes.finalun.data && post.attributes.finalun.data.id == idlist ?<p>{post.attributes && post.attributes.designationtestun ? post.attributes.designationtestun : null}</p> : null}
                    {post.attributes.finalun.data && post.attributes.finalun.data.id == idlist ?<p>{post.attributes && post.attributes.codetestun ? post.attributes.codetestun : null}</p> : null}
                    {post.attributes.finalun.data && post.attributes.finalun.data.id == idlist ?<p>{post.attributes && post.attributes.crptestun ? post.attributes.crptestun : null}</p> : null}
                    {post.attributes.finalun.data && post.attributes.finalun.data.id == idlist ?<p>{post.attributes && post.attributes.pricetestun ? post.attributes.pricetestun : null}</p> : null}
                    <FinalListOptionun id={post.id} />
                    {post.attributes.finalun.data && post.attributes.finalun.data.id == idlist ?<p  onClick={() => deleteRow(post.id)} className="btn btn-danger">Supprimer</p> : null}
                    {post.attributes.finalun.data && post.attributes.finalun.data.id == idlist ?<Link to={url.final_list_editun + '/' + post.id } ><p className="btn btn-info">Éditer</p></Link> : null}
                </div>
                ))}
            
        </div>
    )
}

export default FinalListun