import React, { useState, useEffect } from 'react'
import axios from 'axios'
import JSONPretty from "react-json-pretty";
import * as url from '../include/var.js'
import {
  BrowserRouter as Router,
  Link,
  useParams
} from "react-router-dom"
import Navi from '../include/Navi'
import Foot from '../include/Foot'
import Head from '../include/Head'
import { withRouter } from 'react-router'



function ProductGridApibisun({ evtId }) {
  const [image, setImage] = useState(null)
  const [isClick, setIsClick] = useState(false)
  const [posts, setPosts] = useState([])
  const { idproductgridcreate } = useParams()
  const [message, setMessage] = useState('')
  const [updatesData, setUpdatesData] = useState({
    titletest: "",
  })

  function updateEdit(e) {
    const newupdate = { ...updatesData }
    newupdate[e.target.id] = e.target.value
    setUpdatesData(newupdate)
    handleChange(e)
    console.log(newupdate)
  }

  const handleClick = () => {
    setIsClick(current => !current);
  }

  const handleChange = (event) => {
    setMessage(event.target.value)
  }

  const handleSubmit = async (e) => {
    console.log('handleSubmit')
    e.preventDefault()

    const formData = new FormData()
    formData.append('files', image) // the pic
    formData.append('ref', 'api::testun.testun') // link with my table
    formData.append('refId', evtId)
    //formData.append('field', 'picproductgroup') // the row
    axios.post(url.api_url + '/api/upload/', formData).then(res => {
      console.log(res.data[0].id)
      // if (message.trim().length !== 0) {
      const res2 = axios.post(url.api_url + '/api/testuns', {
        "data": {
          titletest: updatesData.titletest,
          pictest: res.data[0].id,
        }
      }
      )

      console.log(res.data)
      if (res2.ok) {
        console.log(res2)
        console.log('res.ok')
        console.log('res', res2)
      }
    }).catch(error => {
      console.log(error.message);
    })
  }

  const handleFileChange = (e) => {
    if (e.target.files[0].name.split('.').pop() !== "png" && e.target.files[0].name.split('.').pop() !== "jpg" &&
      e.target.files[0].name.split('.').pop() !== "jpeg") {
      alert("Il y a un problème avec le format de l'image")
    } else {
      setImage(e.target.files[0])
    }
  }

  return (
    <div>
      <Head />
      <Navi />
      <div className="login-area">
        <div className="container">
          <div className="login-box ptb--100">
            <div className='login-boxs'>
              <div className="login-form-head">
                <h4>Créer un produit</h4>
              </div>
              <div className="login-form-body">
                <form onSubmit={handleSubmit}>
                  <div class="form-group file-area">
                    <input className='input-size' id='files' type='file' onChange={handleFileChange} />
                    <button className='btn btn-primary add-pic' onChange={handleFileChange}>
                      <svg for='files' width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 13.604a.3.3 0 01-.3.3h-9.795V23.7a.3.3 0 01-.3.3h-3.21a.3.3 0 01-.3-.3v-9.795H.3a.3.3 0 01-.3-.3v-3.21a.3.3 0 01.3-.3h9.795V.3a.3.3 0 01.3-.3h3.21a.3.3 0 01.3.3v9.795H23.7a.3.3 0 01.3.3v3.21z" fill="#ffffff"></path></svg>
                      <label className='add-pic-text' for='files'> Ajouter une photo</label>
                    </button>
                  </div>
                  {image && (
                    <div>
                      <img src={URL.createObjectURL(image)} alt="Thumb" />
                    </div>
                  )}
                  <div className="submit-btn-area">
                    <input type='submit' value='Ajouter' className='btn' />
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Foot />
    </div>
  )
}
export default ProductGridApibisun
