import Navi from '../include/Navi'
import OrderTableTrack from './OrderTableTrack'
import Foot from '../include/Foot'
import Head from '../include/Head'


function OrderTracking() {

    return (
        <div>
            <div className="scren__size">
                <Head />
                <Navi />
                <div className='col-lg-12 col-md-6 mt-5'>
                    {/* <Link to={url.option_list} ><p className="btn btn-primary">Créer</p></Link> */}
                </div>
                <div className="product__list">
                    <div>
                        <div className='tableform__color order__table'>
                            <p>PRÉNOM</p>
                            <p>NOM</p>
                            <p>EMAIL</p>
                            <p>ENTREPRISE</p>
                            <p>ÉTAT</p>
                            {/* <p>DEVIS</p>
                            <p>CONTACTER</p> */}
                        </div>
                        <OrderTableTrack />
                    </div>
                </div>
                <Foot />
            </div>
        </div>
    )
}

export default OrderTracking