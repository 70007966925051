import React, { useState, useContext } from 'react'
import AuthApi from './AuthApi'
import AuthContext from './AuthContext'
import { useNavigate, Link } from 'react-router-dom'

const Log = () => {
    const history = useNavigate()
    const [isClickPassword, setIsClickPassword] = useState(false);
    const [isClickMail, setIsClickLMail] = useState(false);
    const [credentials, setCredentials] = useState({
        identifier: '',
        password: '',
    })

    const { setIsAuthenticated } = useContext(AuthContext)

    const handleChange = ({currentTarget}) => {
        const {value, name} = currentTarget
        setCredentials({
            ...credentials,
            [name]: value
        })
    }

    const handleClickPassword = () => {
        setIsClickPassword(current => !current);
      }

      const handleClickMail = () => {
        setIsClickLMail(current => !current);
      }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await AuthApi.authenticate(credentials)
            setIsAuthenticated(true)
            // history.replace('/products')
            history('/ListProductbis')
        } catch(error) {
            console.log(error)
        }
    }


    return (
        <div>
            <div className="login-area">
                <div className="container">
                    <div className="login-box ptb--100">
                        <form onSubmit={handleSubmit}>
                            <div className="login-form-head">
                                <h4>Connection</h4>
                                <p>Bonjour, Veuillez-vous connecter</p>
                            </div>
                            <div className="login-form-body">
                                <div className="form-gp" onClick={handleClickMail}>
                                <input className='form-input' type="text" id="identifier" label='Username' name='identifier' onChange={handleChange} />
                                {credentials.identifier === '' ? <label className='form-label' for="exampleInputEmail1">Adresse Mail</label> : <label className='form-label-focus' for="exampleInputEmail1">Adresse Mail</label>}
                                    
                                    <i className="ti-email"></i>
                                    <div className="text-danger"></div>
                                </div>
                                <div className="form-gp" onClick={handleClickPassword} >
                                    <input className='form-input' type="password" id="password" label='Password' name='password' onChange={handleChange} />
                                    {credentials.password === '' ? <label className='form-label' for="exampleInputPassword1">Mot de passe</label> : <label className='form-label-focus' for="exampleInputPassword1">Mot de passe</label>}
                                    <i className="ti-lock"></i>
                                    <div className="text-danger"></div>
                                </div>
                                <div className="row mb-4 rmber-area">
                                    <div className="col-6">
                                        <div className="custom-control custom-checkbox mr-sm-2">
                                            <input type="checkbox" className="custom-control-input" id="customControlAutosizing" />
                                            <label className="custom-control-label" for="customControlAutosizing">Se rappeler de moi</label>
                                        </div>
                                    </div>
                                    {/* <div className="col-6 text-right">
                                        <a href="register.html">Mot de passe oublié?</a>
                                    </div> */}
                                </div>
                                <div className="submit-btn-area">
                                <button onclick="window.location.href='product_group.html'">Click Here</button>
                                {}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Log
