import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Select from 'react-select/async'
import * as url from '../include/var.js'
import JSONPretty from "react-json-pretty";
import {
    BrowserRouter as Router,
    Link,
    useParams
} from "react-router-dom"
import Navi from '../include/Navi'
import Foot from '../include/Foot'
import Head from '../include/Head'
import { withRouter } from 'react-router'



function FinalListEdit({ evtId }) {
    const [posts, setPosts] = useState([])
    const { idlist } = useParams()
    const [results, setResults] = useState([])
    const [message, setMessage] = useState('')
    const [messaget, setMessaget] = useState('')
    const [messageth, setMessageth] = useState('')
    const [updatesData, setUpdatesData] = useState({
        codetest: "",
        designationtest: "",
        crptest: "",
        pricetest: "",
        optionid: '',
    })

    const [selectedOptions, setSelectedOptions] = useState(null);
    const [selectData, setSelectData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    

    // Définir setIsLoading sur false une fois que les données ont été chargées
    useEffect(() => {
        axios.get(url.api_url + '/api/finallists?populate=*')
            .then(response => {
                let options = [];
                response.data.data.forEach(item => {
                    if (item.id == idlist && item.attributes.option_managements) {
                        item.attributes.option_managements.data.forEach(option => {
                            options.push({
                                label: option.attributes.designationoption,
                                value: option.id,
                            })
                        });
                    }
                });
                setSelectedOptions(options);
                setIsLoading(false); // Ajouter cette ligne
            })
            .catch(err => console.log(err));
    }, []);

    const handleChange = (event, options) => {
        setMessage(event.target.value)
        updateEdit(event)
        setSelectedOptions(options);
    }



    function updateEdits(e) {
        const newupdate = { ...updatesData }
        newupdate["optionid"] = results
        console.log(e)
        setUpdatesData(newupdate)
    }

    function updateEdit(e) {
        const newupdate = { ...updatesData }
        newupdate[e.target.id] = e.target.value
        setUpdatesData(newupdate)
        setResults(selectedOptions.map(option => ({ id: option.value })))  // Ajouter cette ligne
        console.log(newupdate)
    }

    function checkSelect(e, value) {
        const dd = e.map(e => ({ id: e.value }))
        setResults(dd)
    }

    const handleChanget = (event) => {
        setMessaget(event.target.value)
        updateEdit(event)
    }

    const handleChangeth = (event) => {
        setMessageth(event.target.value)
        updateEdit(event)
    }

    const loadOptions = () => {
        // return fetch(url.api_url + '/api/option-managements?&sort=refoption:asc/')
        return fetch(url.api_url + '/api/option-managements?&sort=refoption:asc')
            .then((res) => res.json())
            .then((data) => data.data.map(option => ({
                label: option.attributes.refoption,
                value: option.id
            })));
    }

    useEffect(() => {
        axios.get(url.api_url + '/api/finallists/' + idlist)
            .then(response => {
                setUpdatesData({
                    ...updatesData,
                    codetest: response.data.data.attributes.codetest,
                    designationtest: response.data.data.attributes.designationtest,
                    crptest: response.data.data.attributes.crptest,
                    pricetest: response.data.data.attributes.pricetest,
                });
            })
            .catch(err => console.error(err));
    }, []);

    const handleChangeForCodetest = (event) => {
        setMessage(event.target.value)
        updateEdit(event)
    }

    const handleSubmit = async (e) => {
        console.log('handleSubmit')
        e.preventDefault()

        const formData = new FormData()
        formData.append('ref', 'api::finallist.finallist') // link with my table
        formData.append('refId', evtId)
        console.log(results)
        //formData.append('field', 'picoption') // the row
       
            const res2 = axios.put(url.api_url + `/api/finallists/` + idlist, {
                "data": {
                    codetest: updatesData.codetest,
                    designationtest: updatesData.designationtest,
                    crptest: updatesData.crptest,
                    pricetest: updatesData.pricetest,
                    "option_managements":
                        results
                }
            }
            )
            if (res2.ok) {
                console.log(res2)
                console.log('res.ok')
                console.log('res', res2)
            }
            alert("la fiche a été générée")
        
    }

    return (
        <div class="page-container">
            <Navi />
            <div class="main-content">
                <Head />
                <div class="main-content-inner">
                    <div class="col-lg-6 mt-5">
                        <div class="card">
                            <div class="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div class="single-table">
                                        <div className="product__list__two">
                                            <div className='tableform__color option__table__two'>
                                                <p>CODE</p>
                                                <p>DÉSIGNATION</p>
                                                <p>CRP</p>
                                                <p>PRIX</p>
                                                <p>OPTIONS</p>
                                            </div>
                                            <div className='option__table__two'>
                                                <input className="option__table__two__input" onChange={(e) => handleChangeForCodetest(e)} id="codetest" value={updatesData.codetest} type="text" placeholder={posts && posts.codetest ? posts.codetest : null} />
                                                <textarea className='option__table__two__textarea' onChange={(e) => handleChanget(e)} id="designationtest" value={updatesData.designationtest} type="text" placeholder={posts && posts.designationtest ? posts.designationtest : null} />
                                                <input className="option__table__two__input" onChange={(e) => handleChangeth(e)} id="crptest" value={updatesData.crptest} type="text" placeholder={posts && posts.crptest ? posts.crptest : null} />
                                                <input className="option__table__two__input" onChange={(e) => handleChangeth(e)} id="pricetest" value={updatesData.pricetest} type="text" placeholder={posts && posts.pricetest ? posts.pricetest : null} />
                                                {isLoading ? (
                                                    <div>Loading...</div>
                                                ) : selectedOptions?.length > 0 ? (
                                                    <Select
                                                        defaultValue={selectedOptions}
                                                        cacheOptions
                                                        defaultOptions
                                                        isMulti
                                                        loadOptions={loadOptions}
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.value}
                                                        onChange={(e) => checkSelect(e, "optionid")}
                                                    />
                                                ) : (
                                                    <Select
                                                        cacheOptions
                                                        defaultOptions
                                                        isMulti
                                                        loadOptions={loadOptions}
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.value}
                                                        onChange={(e) => checkSelect(e, "optionid")}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-5">
                                        <div className="card TableForm__BottomButton">
                                            <div className="card-body">
                                                <input type='submit' value='Enregister' className='btn btn-primary mb-3' />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Foot />
        </div>
    )
}

export default FinalListEdit

