import React from 'react'
import EstimateApiList from './EstimateList'
import './EstimateApiFront.css'

function EstimateApiFront() {

    return (
        <div>
                <div className="table-light grid-name">
                        <p>#</p>
                        <p>Nom Entreprise</p>
                        <p>Prix</p>
                        <p>Date</p>
                        </div>
                <EstimateApiList />
                
        </div>
    )
}

export default EstimateApiFront