import React, { Component } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import {
    Link
} from "react-router-dom"

class OptionGrid extends Component {
    state = {
        optionmanagements: [],
        error: null,
    }

    componentDidMount = async () => {
        try {
            const response = await axios.get(url.api_url + '/api/option-managements?populate=*&sort=id:desc');

            this.setState({ optionmanagements: response.data.data });



        } catch (error) {
            this.setState({ error });
        }
    }

    deleteRow(id, idpic) {
        console.log(id)
        axios.delete(url.api_url + `/api/upload/files/` + idpic)
        axios.delete(url.api_url + `/api/option-managements/` + id)
            .then(res => {
                console.log(res);
                console.log(res.data);

                const optionmanagements = this.state.optionmanagements.filter(item => item.id !== id);
                this.setState({ optionmanagements });
            })
    }
    

    render() {
        const { error, optionmanagements, listgroups } = this.state;

        // Print errors if any
        if (error) {
            return <div>An error occured: {error.message}</div>;
        }
        return (
            <div>
                {this.state.optionmanagements.map(optionmanagement => (
                    <div key={optionmanagement.id}>
                        
                            <div className='option__table'>
                                <img src={optionmanagement.attributes.picoption.data && optionmanagement.attributes.picoption.data.attributes.url ? url.api_url + optionmanagement.attributes.picoption.data.attributes.url : null} alt="image" />
                                <p>{optionmanagement.attributes && optionmanagement.attributes.refoption ? optionmanagement.attributes.refoption : null}</p>
                                <p>{optionmanagement.attributes && optionmanagement.attributes.designationoption ? optionmanagement.attributes.designationoption : null}</p>
                                {/* <p>{optionmanagement.attributes.descriptionoption}</p> */}
                                <p>{optionmanagement.attributes && optionmanagement.attributes.crpoption ? optionmanagement.attributes.crpoption : null}</p>
                                <p>{optionmanagement.attributes && optionmanagement.attributes.priceoption ? optionmanagement.attributes.priceoption : null}</p>
                                {/* <img onClick={(e) => this.deleteRow(productgroup.id, e)} src={url.icon_delete} />*/}
                                <p onClick={(e) => this.deleteRow(optionmanagement.id, optionmanagement.attributes.picoption.data.id)} className="btn btn-danger">Supprimer</p>
                                <Link to={optionmanagement && optionmanagement.attributes.picoption.data && optionmanagement.id  && optionmanagement.attributes.picoption.data.id ? url.option_edit + '/' + optionmanagement.id + '/' + optionmanagement.attributes.picoption.data.id : null} ><p className="btn btn-info">Éditer</p></Link>
                                {console.log(optionmanagement.attributes.picoption.data.id)}
                                {/* {optionmanagement.attributes.stateoption === true ? <p>Actif</p> : <p>Inactif</p>} */}

                            </div>
                    </div>
                ))}
            </div>
        )
    }
}

export default OptionGrid