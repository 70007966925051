import React, { Component } from 'react'
import Navi from '../include/Navi'
import GridProductbis from './GridProductbis'
import Foot from '../include/Foot'
import Head from '../include/Head'

function ListProductbis() {
  return (
    <div>
      <div className="scren__size">
        <Head />
        <Navi />
        <div className="col-lg-12 col-md-6 mt-5">
          <GridProductbis />
        </div>
      </div>
      <Foot />
    </div>
  )
}

export default ListProductbis