import React, { useState, useEffect } from 'react'
import {
    Link,
    useParams
} from "react-router-dom"
import './FrontFinishList.css'
import axios from 'axios'

import * as url from '../include/var.js'

function FinalList() {
    const [posts, setPosts] = useState([])
    const [products, setProducts] = useState([])
    const { idlist } = useParams()

    useEffect(() => {
        axios.get(url.api_url + '/api/finallists?populate=*&sort=id:desc')
            .then(res => {
                setPosts(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    function deleteRow(id) {
        console.log(id)
        axios.delete(url.api_url + `/api/finallists/` + id)
        setPosts(
            posts.filter((post) => {
                return post.id !== id;
            })
        );
    }

    return (
        <div>
            {posts?.map((post) => (
                
                <div className='Final__Options'  key={post && post.id ? post.id : null} >
                    {console.log(idlist)}
                    <p>{post.attributes.designationtest}</p>
                    <p>{post.attributes.codetest}</p>
                    {post.attributes.final.data && post.attributes.final.data.id == idlist ? <p>{post.attributes && post.attributes.designationtest ? post.attributes.designationtest : null}</p> : null}
                    {post.attributes.final.data && post.attributes.final.data.id == idlist ? <p>{post.attributes && post.attributes.codetest ? post.attributes.codetest : null}</p> : null}
                    {post.attributes.final.data && post.attributes.final.data.id == idlist ? <p>{post.attributes && post.attributes.crptest ? post.attributes.crptest : null}</p> : null}
                    {post.attributes.final.data && post.attributes.final.data.id == idlist ? <p>{post.attributes && post.attributes.pricetest ? post.attributes.pricetest : null}</p> : null}
                    {/* <FinalListOption id={post && post.id ? post.id : null} /> */}
                    {post.attributes.final.data && post.attributes.final.data.id == idlist ? <p  onClick={() => deleteRow(post.id)} className="btn btn-danger">Supprimer</p> : null}
                    {/* {post.attributes.final.data && post.attributes.final.data.id == idlist ? <Link to={url.final_list_edit + '/' + post.id + '/' + post.attributes.final.data.id} ><p className="btn btn-info">Éditer</p></Link> : null} */}
                    {post.attributes.final.data && post.attributes.final.data.id == idlist ? <Link to={url.final_list_edit + '/' + post.id} ><p className="btn btn-info">Éditer</p></Link> : null}
                </div>
                ))}
            
        </div>
    )
}

export default FinalList