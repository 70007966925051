import React, { useEffect, useState } from 'react'
import {
    useParams
} from "react-router-dom"
import axios from 'axios'
import * as url from '../include/var.js'
import Navi from '../include/Navi'
import Foot from '../include/Foot'
import Head from '../include/Head'

function CustomerEdit() {
    const { idcust } = useParams()
    const [posts, setPosts] = useState([])
    const [updatesData, setUpdatesData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        company: "",
        phonenumber: "",
        siret: "",
        phonenumberport: "",
        adress: "",
    })

    function updateEdit(e) {
        const newupdate = { ...updatesData }
        newupdate[e.target.id] = e.target.value
        setUpdatesData(newupdate)
        console.log(newupdate)
    }

    useEffect(() => {
        axios.get(url.api_url + '/api/customers/' + idcust)
            .then(res => {
                setPosts(res.data.data)
                console.log(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const handleSubmit = async (e) => {
        console.log('handleSubmit')
        e.preventDefault()
        axios.put(url.api_url + '/api/customers/' + idcust, {
            "data": {
                firstname: updatesData.firstname,
                lastname: updatesData.lastname,
                email: updatesData.email,
                company: updatesData.company,
                phonenumber: updatesData.phonenumber,
                siret: updatesData.siret,
                phonenumberport: updatesData.phonenumberport,
                adress: updatesData.adress,
            }
        })
    }

    return (
        <div>
            <div className="scren__size">
                <Head />
                <Navi />
                <div className="product__list">
                    <div className='col-lg-12 col-md-6 mt-5 '>
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="header-title">Création du client</h4>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Nom</label>
                                            <input className='form-control' onChange={(e) => updateEdit(e)} id="lastname" value={updatesData.lastname} type="text" placeholder={posts.attributes && posts.attributes.lastname  ? posts.attributes.lastname : "Enter le nom"} />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Prénom</label>
                                            <input className='form-control' onChange={(e) => updateEdit(e)} id="firstname" value={updatesData.firstname} type="text" placeholder={posts.attributes && posts.attributes.firstname ? posts.attributes.firstname : "Enter le prénom"} />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Nom de l'Entreprise</label>
                                            <input className='form-control' onChange={(e) => updateEdit(e)} id="company" value={updatesData.company} type="text" placeholder={posts.attributes && posts.attributes.company ? posts.attributes.company : "Enter l'entreprise"} />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">SIRET</label>
                                            <input className='form-control' onChange={(e) => updateEdit(e)} id="siret" value={updatesData.siret} type="text" placeholder={posts.attributes && posts.attributes.siret ? posts.attributes.siret : "Enter le siret"} />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Téléphone Fix</label>
                                            <input className='form-control' onChange={(e) => updateEdit(e)} id="phonenumber" value={updatesData.phonenumber} type="text" placeholder={posts.attributes && posts.attributes.phonenumber ? posts.attributes.phonenumber : "Enter le téléphone fix"} />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Téléphone portable</label>
                                            <input className='form-control' onChange={(e) => updateEdit(e)} id="phonenumberport" value={updatesData.phonenumberport} type="text" placeholder={posts.attributes && posts.attributes.phonenumberport ? posts.attributes.phonenumberport : "Enter le téléphone portable"} />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Adresse de l'Entreprise</label>
                                            <input className='form-control' onChange={(e) => updateEdit(e)} id="adress" value={updatesData.adress} type="text" placeholder={posts.attributes && posts.attributes.adress ? posts.attributes.adress : "Enter l'adresse"} />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Adresse E-mail</label>
                                            <input className='form-control' onChange={(e) => updateEdit(e)} id="email" value={updatesData.email} type="text" placeholder={posts.attributes && posts.attributes.email ? posts.attributes.email : "Enter l'email"} />
                                        </div>
                                        <button type="submit" className="btn btn-primary mt-4 pr-4 pl-4">Enregister</button>
                                    </form>
                                </div>
                            </div>
                    </div>
                </div>
                <Foot />
            </div>
        </div>
    )
}

export default CustomerEdit