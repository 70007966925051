import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import JSONPretty from "react-json-pretty";
import {
    BrowserRouter as Router,
    Link,
    useParams
} from "react-router-dom"
import Navi from '../include/Navi'
import Foot from '../include/Foot'
import Head from '../include/Head'
import { withRouter } from 'react-router'



function OptionList({ evtId }) {
    const [image, setImage] = useState(null)
    const [posts, setPosts] = useState([])
    const { idlisttwo } = useParams()
    const [message, setMessage] = useState('')
    const [messaget, setMessaget] = useState('')
    const [messageth, setMessageth] = useState('')
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [updatesData, setUpdatesData] = useState({
        refoption: "",
        designationoption: "",
        crpoption: "",
        priceoption: "",
        stateoption: "true",
    })

    function updateEdit(e) {

        const newupdate = { ...updatesData }
        newupdate[e.target.id] = e.target.value
        setUpdatesData(newupdate)
        console.log(newupdate)
    }

    const handleChange = (event) => {
        setMessage(event.target.value)
        updateEdit(event)
    }

    const handleChanget = (event) => {
        setMessaget(event.target.value)
        updateEdit(event)
    }

    const handleChangeth = (event) => {
        setMessageth(event.target.value)
        updateEdit(event)
    }

    const handleSubmit = async (e) => {
        console.log('handleSubmit')
        e.preventDefault()

        const formData = new FormData()
        formData.append('files', image) // the pic
        formData.append('ref', 'api::option-management.option-management') // link with my table
        formData.append('refId', evtId)
        //formData.append('field', 'picoption') // the row
        if (message.trim().length !== 0 && messaget.trim().length !== 0 &&
            messageth.trim().length !== 0) {
            axios.post(url.api_url + '/api/upload/', formData).then(res => {
                console.log(res.data[0].id);
                const res2 = axios.post(url.api_url + `/api/option-managements/`, {
                    "data": {
                        refoption: updatesData.refoption,
                        designationoption: updatesData.designationoption,
                        crpoption: updatesData.crpoption,
                        priceoption: updatesData.priceoption,
                        stateoption: updatesData.stateoption,
                        picoption: res.data[0].id,
                    }
                }
                )

                console.log(res.data)
                if (res2.ok) {
                    console.log(res2)
                    console.log('res.ok')
                    console.log('res', res2)
                    // imageUploaded()
                }
            }).catch(error => {
                console.log(error.message);
            })
            alert("la fiche a été générée")
        } else {
            alert("Un des champs n'a pas été rempli")
        }

        //e.preventDefault()
    }

    const handleFileChange = (e) => {
        if (e.target.files[0].name.split('.').pop() !== "png" && e.target.files[0].name.split('.').pop() !== "jpg" &&
            e.target.files[0].name.split('.').pop() !== "jpeg") {
            alert("Il y a un problème avec le format de l'image")
        } else {
            setImage(e.target.files[0])
        }
    }

    return (
        <div class="page-container">
            <Navi />
            <div class="main-content">
                <Head />
                <div class="main-content-inner">
                    <div class="col-lg-6 mt-5">
                        <div class="card">
                            <div class="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div class="Image">
                                        <div class="icon-container">
                                            <span class="ti-plus"></span>
                                        </div>
                                        <input className='file-plus' type='file' onChange={handleFileChange} />
                                        {image && (
                                            <div class="icon-container">
                                                <img class="Image__Icon" src={URL.createObjectURL(image)} alt="Thumb" />
                                            </div>
                                        )}
                                    </div>
                                    <div class="single-table">
                                        <div className="product__list__two">
                                            <div className='tableform__color option__table__two'>
                                                <p>RÉF.</p>
                                                <p>DÉSIGNATION</p>
                                                <p>CRP.</p>
                                                <p>RPIX</p>
                                            </div>
                                            <div className='option__table__two'>
                                                <input className="option__table__two__input" onChange={(e) => handleChange(e)} id="refoption" value={updatesData.refoption} type="text" placeholder={posts.refoption} />
                                                <textarea className='option__table__two__textarea' onChange={(e) => handleChanget(e)} id="designationoption" value={updatesData.designationoption} type="text" placeholder={posts.designationoption} />
                                                <input className="option__table__two__input" onChange={(e) => handleChangeth(e)} id="crpoption" value={updatesData.crpoption} type="text" placeholder={posts.crpoption} />
                                                <input className="option__table__two__input" onChange={(e) => handleChangeth(e)} id="priceoption" value={updatesData.priceoption} type="text" placeholder={posts.priceoption} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-5">
                                        <div className="card TableForm__BottomButton">
                                            <div className="card-body">
                                                <input type='submit' value='Enregister' className='btn btn-primary mb-3' />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Foot />
        </div>
    )
}

export default OptionList